import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BodyContext } from '~/context/BodyContext.js'
import { useWindowDimensions } from '~/hooks'

export const Navbar = props => {
    const [CT] = useContext(BodyContext);
    const [activeBurgerMenu, set_activeBurgerMenu] = useState(false)
    const base_path = '';
    const location = useLocation()
    const baseRoute = location.pathname.slice(1).indexOf('/') != -1 ? ('/' + location.pathname.substr(1, location.pathname.slice(1).indexOf('/'))) : location.pathname
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const toggleBurgerMenu = (mode) => {
        set_activeBurgerMenu(mode ? mode : !activeBurgerMenu)
    }
    const r_menuLinks = () => {
        return (
            <>
                <div className="ACTION">
                    <Link
                        onClick={() => toggleBurgerMenu(false)}
                        to={`${base_path}/`}
                        className={`link -icony ${baseRoute === '/' && "-active"}`}>
                        <i className="ri-home-line"></i>
                        <span className="text">home</span>
                    </Link>
                </div>
                {/* <div className="ACTION">
                    <Link
                        onClick={() => toggleBurgerMenu(false)}
                        to={`${base_path}/apps`}
                        className={`link -icony ${baseRoute === '/apps' && "-active"}`}>
                        <i className="ri-apps-2-line"></i>
                        <span className="text">apps</span>
                    </Link>
                </div> */}
                <div className="ACTION">
                    <Link
                        onClick={() => toggleBurgerMenu(false)}
                        to={`${base_path}/about`}
                        className={`link -icony ${baseRoute === '/about' && "-active"}`}>
                        <i className="ri-file-warning-line"></i>
                        <span className="text">about</span>
                    </Link>
                </div>
                <div className="ACTION">
                    <Link
                        onClick={() => toggleBurgerMenu(false)}
                        to={`${base_path}/mywallet`}
                        className={`link -icony ${baseRoute === '/mywallet' && "-active"}`}
                        style={{ border: '1px solid black' }}>
                        <i className="ri-wallet-line"></i>
                        <span className="text">my wallet</span>
                    </Link>
                </div>
                <div className="ACTION -important">
                    <Link
                        onClick={() => toggleBurgerMenu(false)}
                        to={`${base_path}/forSale`}
                        className={`link -icony ${(baseRoute === '/forSale' || baseRoute === '/detail') && "-active"}`}>
                        <i className="ri-shopping-basket-line"></i>
                        <span className="text">market</span>
                    </Link>
                </div>
            </>
        )
    }
    return (
        <div className="NAVBAR">
            <div className="c-">
                <div className="ACTION">
                    <Link
                        to={`${base_path}/`}
                        className={`brand`}>
                        <img src={CT.nightmode ? require('~/assets/images/brand/Logo-Full-Light.png').default : require('~/assets/images/brand/Logo-Full.png').default} />
                    </Link>
                </div>
                <div className="ACTION">
                    <a className="set" onClick={() => CT.set_nightmode(!CT.nightmode)}>
                        <i className={!CT.nightmode ? "ri-moon-fill" : "ri-sun-fill"}></i>
                    </a>
                </div>
                <div className="BURGER">
                    <a
                        style={{
                            borderColor: activeBurgerMenu ? '#000' : 'transparent'
                        }}
                        onClick={() => toggleBurgerMenu()}
                        className={`link -icony`}>
                        <i className="ri-menu-3-line"></i>
                    </a>
                </div>
                <div className="links _onDesktop">
                    {r_menuLinks()}
                </div>
                <div className="links _onPhone"
                    style={{
                        display: activeBurgerMenu ? (windowWidth <= '700' ? 'flex' : 'none') : 'none'
                    }}
                >
                    {r_menuLinks()}
                </div>
            </div>
        </div>
    )
}
