import millify from "millify"

export default {
    normalizeNum: (number, space = true, precision = 1) => {
        return millify(number, {
            units: ["", "K", "M", "B", "T"],
            space,
            precision
        })
    },
    normalizeStatsNum: (number) => {
        return millify(number, {
            units: ["", "K", "M", "B", "T"],
            space: true,
            precision: 2,
        })
    },
    BigToNum: (x) => {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split('e-')[1]);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            var e = parseInt(x.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10, e);
                x += (new Array(e + 1)).join('0');
            }
        }
        return x;
    },
}