import React, { useState, createContext } from 'react'
import ENV from './_env'
import {
    punks as punksData,
    punks_org as punksOrgData,
    filters as filtersData,
    ranks as ranksData
} from '~/data'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import Cookies from 'js-cookie'
import { Wallet, Contract, Api } from '../scripts';
import utils from '../utils';

const ReactSwal = withReactContent(Swal)
const wallet = new Wallet();
const contract = new Contract();
export const BodyContext = createContext();

export const BodyProvider = props => {
    const [nightmode, set_nightmode] = useState(Cookies.get('user') || false);
    const [classing, set_classing] = useState({});
    const [dynamics, set_dynamics] = useState({});
    let CT = {
        classing: classing,
        set_classing: set_classing,
        nightmode: nightmode,
        set_nightmode: (mode) => {
            mode ? Cookies.set('user', true, { expires: 7 }) : Cookies.remove('user')
            set_nightmode(mode)
        },
        storage: {
            PUNKS: punksData,
            ORG_PUNKS: punksOrgData,
            // PUNKS_SORTED_BY_RANK: punksSortedByRankData,
            FILTERS: filtersData,
            RANKS: ranksData,
        },
        statics: {
            homePunk: 81,
            contract: {
                address: {
                    TPUNKS_MARKET: ENV.TPUNKS_MARKET,
                    TRONSCAN: ENV.TRONSCAN,
                },
                feeLimit: 100000000,
                feeLimitOn: (currency) => 100000000,
            },
            socials: {
                telegram_group: ENV.TELEGRAM_GROUP,
                telegram_channel_recently: ENV.TELEGRAM_CHANNEL_RECENTLY,
                telegram_channel_feed: ENV.TELEGRAM_CHANNEL_FEED,
                twitter: ENV.TWITTER,
                twitter_bot: ENV.TWITTER_BOT,
                tronscan: `${ENV.TRONSCAN}/#/contract/${ENV.TPUNKS}/transactions`
            },
            limits: {
                minOfferValueOn: (currency) => {
                    switch (currency) {
                        case 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb':
                            // return 5000
                            return 1
                        case 'TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq':
                            // return 150000000
                            return 1
                        default:
                            // return 1
                            return 1
                    }
                },
                minBidValueOn: (currency) => {
                    switch (currency) {
                        case 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb':
                            // return 5000
                            return 1
                        case 'TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq':
                            // return 150000000
                            return 1
                        default:
                            // return 1
                            return 1
                    }
                },
            }
        },
        popup: {
            open: (mode, data) => {
                return new Promise((resolve, reject) => {
                    let initialData = {
                        title: <div className="swal--head">{data.title || 'title'}</div>,
                        icon: mode ? mode.toLowerCase() : null
                    }
                    ReactSwal.fire({
                        ...initialData,
                        showCloseButton: true,
                        focusConfirm: true,
                        focusCancel: false,
                        focusDeny: false,
                        html: <>
                            <div className="swal--content">
                                {data.body || ''}
                            </div>
                        </>,
                        customClass: {
                            container: 'swal',
                            popup: 'swal-box',
                            title: 'swal--title',
                            closeButton: 'swal-closebtn',
                            denyButton: 'swal-denybtn',
                            confirmButton: 'Btn -force',
                            cancelButton: '',
                        },
                        ...data.modify || null,
                    })
                        .then(res => {
                            if (res.isConfirmed) {
                                resolve(true)
                            } else if (res.isDenied) {
                                reject('DENY')
                            }
                        })
                        .catch(err => {
                            reject('CANCEL')
                        })
                })
            },
            confirm: () => ReactSwal.clickConfirm(),
            deny: () => ReactSwal.clickDeny(),
            cancel: () => ReactSwal.clickCancel(),
        },
        toast: (mode, msg) => {
            toast[mode || 'info'](msg, {
                position: "bottom-left",
                autoClose: 8000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        },
        tooltip: (type, id, content) => {
            return <ReactTooltip id={id} type={type}>
                {content}
            </ReactTooltip>
        },
        utils: {
            cryptoIcon: (crypto) => require(`cryptocurrency-icons/svg/color/${crypto}.svg`).default,
            pis: (id) => process.env.PUBLIC_URL + `/images/tpunks/${id}.png`, // pis = Punk Image Source
            walletAddress: (address, title) => {
                return address && <div className="--Owner">
                    <a target='_blank' href={`${process.env.REACT_APP_TRONSCAN}/?address/${address}#/address/${address}`}>{title || (address.length > 10 ? address.substr(0, 6) + '...' + address.substr(address.length - 4, address.length) : address)}</a>
                </div>
            },
            getCurrencies: () => {
                return new Promise(async (resolve, reject) => {
                    const currs = await Api.get('/currencies')
                    currs ? resolve(currs) : reject(false)
                })
            },
            findInBy: (val, arr, by) => {
                let data = null
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i][by] == val) {
                        data = arr[i]
                        break;
                    }
                }
                return data
            },
            eventTitle: (title) => {
                switch (title) {
                    case 'Minted':
                        return 'Minted'
                    case 'TokenListed':
                        return 'Listed'
                    case 'TokenUnlisted':
                        return 'Delisted'
                    case 'BidCanceled':
                        return 'Bid Canceled'
                    case 'BidCreated':
                        return 'Bid'
                    case 'TokenTransferred':
                        return 'Transfer'
                    case 'TokenSold':
                        return 'Sold'
                    default:
                        return title
                }
            },
            ...utils
        },
        wallet: {
            _: wallet,
            init: async () => {
                // const a = await wallet.connect()
                // console.log(a)
                return wallet.connect()
            },
            getAddress: async () => {
                return wallet.getAddress()
            }
        },
        dynamics: dynamics,
        set_dynamics: (data) => {
            set_dynamics({
                ...dynamics,
                ...data
            })
        },
        contract: contract,
        cookies: Cookies,
    }
    return (
        <BodyContext.Provider value={[CT]}>
            {props.children}
        </BodyContext.Provider>
    )
}
