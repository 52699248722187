import React, { Component } from 'react'
import InputRange from 'react-input-range';
import Logarithmic from './Logarithmic';
import { Watch, Actions, Api, WalletManager } from '~/scripts'
import utils from '../../../utils';
import C from '~/components';
import J from 'juicy-ui'

export default class Page extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                sorts: [
                    'lowest id',
                    'highest id',
                    // 'only have bid',
                    'lowest price',
                    'highest price',
                    'lowest bid',
                    'highest bid',
                    'lowest rank',
                    'highest rank',
                ],
                sorts_onAll: [
                    'lowest id',
                    'highest id',
                    'lowest rank',
                    'highest rank',
                ]
            },
            filters: {
                attrs: this.props.init.attrs || [],
                attrCount: this.props.init.attrCount || [],
                types: this.props.init.types || [],
                priceRange: {
                    // min: this.props.init.initPriceRange.min > -1 ? this.props.init.initPriceRange.min : this.props.init.min_price,
                    // max: this.props.init.initPriceRange.max > -1 ? this.props.init.initPriceRange.max : this.props.init.max_price

                    // min: this.props.init.initPriceRange.min,
                    // max: this.props.init.initPriceRange.max,
                    min: this.props.init.initPriceRange.min,
                    max: this.props.init.initPriceRange.max,
                },
                sortBy: this.props.init.sort_title || '',
            },
            inputRange: {
                // min: this.props.init.initPriceRange.min > -1 ? this.props.init.initPriceRange.min : this.props.init.min_price,
                // max: this.props.init.initPriceRange.max > -1 ? this.props.init.initPriceRange.max : this.props.init.max_price
                min: this.props.init.initPriceRange.min > 0 ? this.props.init.initPriceRange.min : 0,
                max: this.props.init.initPriceRange.max < 100 ? this.props.init.initPriceRange.max : 100
            },
            // value: { min: 0, max: 100 }
        }
        this.logSlider = new Logarithmic({ minpos: 0, maxpos: 100, minval: this.props.init.min_price, maxval: this.props.init.max_price });
        // this.logSlider = new Logarithmic({ minpos: 0, maxpos: 100, minval: 300, maxval: 180000000 });
    }
    _sst(main, obj, callback) {
        this.setState({
            [main]: {
                ...this.state[main],
                ...obj
            }
        }, () => callback ? callback() : null)
    }
    filter(on, item) {
        let fils = this.state.filters[on]
        let priceRange = {
            minPrice: this.logSlider.value(this.state.filters.priceRange.min),
            maxPrice: this.logSlider.value(this.state.filters.priceRange.max),
            minPos: this.state.filters.priceRange.min,
            maxPos: this.state.filters.priceRange.max
        }
        if (['attrs', 'attrCount', 'types'].includes(on)) {
            if (item == 'all') {
                fils = []
            } else {
                const index = fils.indexOf(item);
                if (index === -1) fils.push(item)
                else fils.splice(index, 1)
            }
        } else if (on === 'priceRange') {
            // let item_ = { ...item }
            // if (item_.min === this.props.init.min_price && item_.max === this.props.init.max_price) {
            //     item_.min = -1;
            //     item_.max = -1;
            // }
            fils = {
                min: item.min,
                max: item.max
            };
            priceRange = {
                minPrice: this.logSlider.value(item.min),
                maxPrice: this.logSlider.value(item.max),
                minPos: item.min,
                maxPos: item.max
            }
        }
        this._sst('filters', { [on]: fils }, () => {
            this.props.onFilter({
                attrs: this.state.filters.attrs,
                types: this.state.filters.types,
                attrCount: this.state.filters.attrCount,
                // priceRange: this.state.filters.priceRange
                priceRange
            })
        })
    }
    sort(item) {
        let sortBy = this.state.filters.sortBy
        sortBy = item
        this._sst('filters', { sortBy })
        this.props.onSort(sortBy)
    }
    render() {
        const { types = null, attributes, attributeCount } = this.props.filters
        const attrs = Object.keys(attributes).sort().reduce(
            (obj, key) => {
                obj[key] = attributes[key];
                return obj;
            },
            {}
        );
        const sorts = this.state.data.sorts
        const sorts_onAll = this.state.data.sorts_onAll
        const fils_attrs = this.state.filters.attrs
        const fils_attr_count = this.state.filters.attrCount
        const fils_types = this.state.filters.types
        const fils_sortBy = this.state.filters.sortBy
        return (
            <div className="popup--form-item">
                {this.props.isMarket
                    ?
                    <>
                        <div className="popup--form-title">
                            <span>
                                sort by
                            </span>
                        </div>
                        <div className="popup--selector">
                            <div className="popup--selector-item">
                                <span className={`fil ${fils_sortBy == '' ? '-active' : ''}`} onClick={() => this.sort('')}>{`normal`}</span>
                            </div>
                            {sorts.map((item, i) => {
                                return <div className="popup--selector-item" key={i}>
                                    <span className={`fil ${fils_sortBy == item ? '-active' : ''}`} onClick={() => this.sort(item)}>{item}</span>
                                </div>
                            })}
                        </div>
                    </>
                    :
                    <>
                        <div className="popup--form-title">
                            <span>
                                sort by
                            </span>
                        </div>
                        <div className="popup--selector">
                            <div className="popup--selector-item">
                                <span className={`fil ${fils_sortBy == '' ? '-active' : ''}`} onClick={() => this.sort('')}>{`normal`}</span>
                            </div>
                            {sorts_onAll.map((item, i) => {
                                return <div className="popup--selector-item" key={i}>
                                    <span className={`fil ${fils_sortBy == item ? '-active' : ''}`} onClick={() => this.sort(item)}>{item}</span>
                                </div>
                            })}
                        </div>
                    </>
                }
                {
                    this.props.isMarket &&
                    <>
                        <div className="popup--form-title">
                            <span>
                                filter by price range
                            </span>
                        </div>
                        <div className="popup--selector price-range">
                            <div className="popup--selector-item">
                                {/* {
                                    console.log({
                                        inputRange: this.state.inputRange
                                    })
                                } */}
                                {/* <InputRange
                                    tabIndex="0"
                                    allowSameValues={true}
                                    maxValue={this.props.init.max_price}
                                    minValue={this.props.init.min_price}
                                    disabled={!(isFinite(this.state.inputRange.min) && isFinite(this.state.inputRange.max))}
                                    step={0.001}
                                    value={this.state.inputRange}
                                    formatLabel={(value) => isFinite(value) ? `$${utils.normalizeNum(value, false)}` : ''}
                                    draggableTrack={true}
                                    onChange={(values) => {
                                        if (values.min < this.props.init.min_price || Math.abs(values.min - this.props.init.min_price) < 0.1) {
                                            values.min = this.props.init.min_price
                                        }
                                        if (values.max > this.props.init.max_price || Math.abs(values.max - this.props.init.max_price) < 0.1) {
                                            values.max = this.props.init.max_price
                                        }

                                        this.setState({ inputRange: values })
                                    }}
                                    onChangeComplete={(values) => {

                                        // if( values.min === this.props.init.min_price && values.max === this.props.init.max_price) {
                                        //     values.min = -1
                                        //     values.max = -1
                                        // }
                                        this.filter('priceRange', values)
                                    }}
                                /> */}
                                {/* <div style={{ marginTop: '50px' }}></div> */}
                                {/* <InputRange
                                    step={1}
                                    formatLabel={(value) => {
                                        if (value === 0) return 0;
                                        const val = this.logSlider.value(value);
                                        return `$${utils.normalizeNum(val, false)}`;
                                    }}
                                    maxValue={100}
                                    minValue={0}
                                    value={this.state.value}
                                    onChange={(values) => this.setState({ value: values })}
                                /> */}
                                {/* <div style={{ marginTop: '50px' }}></div> */}
                                <InputRange
                                    step={0.01}
                                    minValue={0}
                                    maxValue={100}
                                    draggableTrack={true}
                                    disabled={!(isFinite(this.props.init.min_price) && isFinite(this.props.init.max_price))}
                                    formatLabel={(value) => {
                                        if(!(isFinite(this.props.init.min_price) && isFinite(this.props.init.max_price))) {
                                            return '';
                                        }
                                        if (value === 0) return 0;
                                        const val = this.logSlider.value(value);
                                        // return `$${utils.normalizeNum(val, false)}`;
                                        console.log('ttttt', val)
                                        return isFinite(val) ? `$${utils.normalizeNum(val, false)}` : ''
                                    }}
                                    value={this.state.inputRange}
                                    onChange={(values) => {
                                        if (values.min < 0) {
                                            values.min = 0
                                        }
                                        if (values.max > 100) {
                                            values.max = 100
                                        }
                                        console.log('onChange ', values)
                                        this.setState({ inputRange: values })
                                    }}
                                    onChangeComplete={(values) => {
                                        this.filter('priceRange', values)
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
                <div className="popup--form-title">
                    <span>
                        filter by type
                    </span>
                </div>
                <div className="popup--selector">
                    <div className="popup--selector-item">
                        <span className={`fil ${fils_types.length <= 0 ? '-active' : ''}`} onClick={() => this.filter('types', 'all')}>{`all`}</span>
                    </div>
                    {Object.keys(types).map(i => {
                        return <div className="popup--selector-item" key={i}>
                            <span className={`fil ${fils_types.includes(types[i].title) ? '-active' : ''}`} onClick={() => this.filter('types', types[i].title)}>{types[i].title}&nbsp;<strong style={{ opacity: '0.4' }}>{this.props.isMarket ? (types[i]._count || '-') : (types[i].count || '-')}</strong></span>
                        </div>
                    })}
                </div>
                <div className="popup--form-title">
                    <span>
                        filter by attribute count
                    </span>
                </div>
                <div className="popup--selector">
                    <div className="popup--selector-item">
                        <span className={`fil ${fils_attr_count.length <= 0 ? '-active' : ''}`} onClick={() => this.filter('attrCount', 'all')}>{`all`}</span>
                    </div>
                    {Object.keys(attributeCount).map(key => {
                        return <div className="popup--selector-item" key={key}>
                            <span className={`fil ${fils_attr_count.includes(key) ? '-active' : ''}`} onClick={() => this.filter('attrCount', key)}>{key}&nbsp;<strong style={{ opacity: '0.4' }}>{this.props.isMarket ? (attributeCount[key]._count || '-') : (attributeCount[key].count || '-')}</strong></span>
                        </div>
                    })}
                </div>
                <div className="popup--form-title">
                    <span>
                        filter by attribute
                    </span>
                </div>
                <div className="popup--selector">
                    <div className="popup--selector-item">
                        <span className={`fil ${fils_attrs.length <= 0 ? '-active' : ''}`} onClick={() => this.filter('attrs', 'all')}>{`all`}</span>
                    </div>
                    {Object.keys(attrs).map(i => {
                        return <div className="popup--selector-item" key={i}>
                            <span className={`fil ${fils_attrs.includes(attrs[i].title) ? '-active' : ''}`} onClick={() => this.filter('attrs', attrs[i].title)}>{attrs[i].title}&nbsp;<strong style={{ opacity: '0.4' }}>{this.props.isMarket ? (attrs[i]._count || '-') : (attrs[i].count || '-')}</strong></span>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}