import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import './styles/App.scss';
import 'remixicon/fonts/remixicon.css'
import 'simplebar/dist/simplebar.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { BodyProvider } from './context/BodyContext.js'
import Routing from './Router/Routing'
import { ToastContainer } from 'react-toastify';
require('dotenv').config()

function App() {

  // if (window.location.protocol == 'http:') {
  //   window.location.href = "https://tpunks.com";
  //   return;
  // }
  //
  // if((window.location.href.indexOf('unks.ap') > -1))
  return (
    <div className="APP">
      <Router>
        <BodyProvider>
          <Switch>
            {/* Routes */}
            <Route path="/" component={Routing.guest} />
            <Route path="/dashboard" component={Routing.user} />
            <Route path="/admin" component={Routing.admin} />
            <Redirect path='*' to="/" />
          </Switch>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BodyProvider>
      </Router>
    </div>
  );
  // else
  //   return (
  //       <div className="APP">
  //
  //       </div>
  //   );
}

export default App;
