import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import { Watch, Actions, Api } from '~/scripts'
import { Swiper, SwiperSlide } from "swiper/react";
import C from '~/components';
import J from 'juicy-ui'

export default class Page extends Component {
    static contextType = BodyContext;
    constructor() {
        super()
        this.state = {
            interf: {
                isLoading: false
            },
            storage: {
                overalStats: null,
                currencies: null
            }
        }
    }
    componentWillMount() {
        const [CT] = this.context
        this.CT = CT
        CT.set_classing({ route: 'home' })
    }
    componentDidMount() {
        Watch.init(this)
        this.CT.utils.getCurrencies().then(res => this._sst('storage', { currencies: res }))
        this._loadData()
    }
    _sst(main, obj) {
        this.setState({
            [main]: {
                ...this.state[main],
                ...obj
            }
        })
    }
    async _loadData() {
        let overalStats = null
        this._sst('interf', { isLoading: true })
        try {
            overalStats = await Api.get('/overalStats')
            this._sst('storage', { overalStats: overalStats || null })
            this._sst('interf', { isLoading: false })
        }
        catch (err) {
            this.CT.toast('error', 'server did not respond. try later')
            console.log(err)
        }
    }
    openFeedBots() {
        this.CT.popup.open(null, {
            title: 'FEED BOTS',
            modify: {
                showConfirmButton: false,
            },
            body: <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
                    <a href={this.CT.statics.socials.telegram_channel_recently || '/'} target="_blank" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '6px 10px', marginBottom: '10px' }}>
                        <J.Image width="20px" height="20px" src={require('~/assets/images/interface/social-telegram.png').default} />&nbsp;
                        <span>
                            recently listed feed
                        </span>
                    </a>
                    <a href={this.CT.statics.socials.telegram_channel_feed || '/'} target="_blank" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '6px 10px', marginBottom: '10px' }}>
                        <J.Image width="20px" height="20px" src={require('~/assets/images/interface/social-telegram.png').default} />&nbsp;
                        <span>
                            marketplace feed
                        </span>
                    </a>
                    <a href={this.CT.statics.socials.twitter_bot || '/'} target="_blank" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '6px 10px', marginBottom: '10px' }}>
                        <J.Image width="20px" height="20px" src={require('~/assets/images/interface/social-twitter.png').default} />&nbsp;
                        <span>
                            marketplace feed
                        </span>
                    </a>
                </div>
            </>
        })
            .then(res => null)
            .catch(err => null);
    }

    render() {
        const { interf, storage } = this.state
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        return (
            <>
                <section className="main">
                    <div className="hero">
                        <div className="whatisit">
                            <div className="image">
                                <J.Image src={this.CT.utils.pis(this.CT.statics.homePunk)} />
                            </div>
                            <div className="info">
                                <div className="description">
                                    <h3 className="title">
                                        <strong>TPUNKS</strong> THE FIRST NFT ON TRON BLOCKCHAIN.
                                        
                                    </h3>
                                    <p className="body">
                                        there are 10,000 unique collectible characters like cryptopunks on the tron blockchain. contains 9 aliens, 24 apes, 88 zombies, and 11 justin.
                                    </p>

                                </div>

                                {/* <div className="title">
                                    <h2>
                                        <strong>TPUNKS</strong> MARKETPLACE
                                    </h2>
                                </div> */}
                                <div className="action">
                                    <p>
                                        trade tpunks here
                                    </p>
                                    <Link className="Btn" to="/forSale">
                                        <span>
                                            GO TO MARKET
                                        </span>
                                    </Link>
                                </div>
                                <div className="extra">
                                    {interf.isLoading
                                        ?
                                        <C.Loading />
                                        :
                                        (storage.overalStats &&
                                            <div className="stat">
                                                {storage.overalStats.currentLowestPricePunkAvailable &&
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                Lowest Price Punk Available
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <div className="--Price">
                                                                <span className="number">
                                                                    $ {this.CT.utils.normalizeStatsNum(storage.overalStats.currentLowestPricePunkAvailable)}
                                                                </span>
                                                                <span className="sign">
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {storage.overalStats.totalVolumeOfSalesLifetime &&
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                total volume traded
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <div className="--Price">
                                                                <span className="number">
                                                                    $ {this.CT.utils.normalizeStatsNum(storage.overalStats.totalVolumeOfSalesLifetime)}
                                                                </span>
                                                                <span className="sign">
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {storage.overalStats.floorPrice &&
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                floor price
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <div className="--Price">
                                                                <span className="number">
                                                                    $ {this.CT.utils.normalizeStatsNum(storage.overalStats.floorPrice)}
                                                                </span>
                                                                <span className="sign">
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {storage.overalStats.numberOfSalesLifeTime &&
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                Number Of Sales - LifeTime
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <div className="--Price">
                                                                <span className="number">
                                                                    {this.CT.utils.normalizeStatsNum(storage.overalStats.numberOfSalesLifeTime)}
                                                                </span>
                                                                <span className="sign">
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="news">
                            <div className="title">
                                <div className="name">
                                    <span>
                                        largest sales
                                    </span>
                                </div>
                            </div>
                            <div className="list">
                                {interf.isLoading
                                    ?
                                    <C.Loading />
                                    :
                                    (storage.overalStats && storage.currencies
                                        ?
                                        <Swiper
                                            freeMode
                                            className="custome_swiper"
                                        >
                                            {storage.overalStats.largestSales.map((punk, i) => {
                                                return <SwiperSlide>
                                                    <Link className="punk" to={`detail/Tpunks/${punk.punkId}`} key={i}>
                                                        <div className="image">
                                                            <J.Image className="--Punk_image" src={this.CT.utils.pis(punk.punkId)} />
                                                        </div>
                                                        <div className="info">
                                                            <div className="name">
                                                                <span>
                                                                    {punk.punkId}
                                                                </span>
                                                            </div>
                                                            <div className="price">
                                                                <div className="--Price -sub">
                                                                    <span className="number">
                                                                        {punk.value / Math.pow(10, getCurrencyData(punk.currency_address).decimals)}
                                                                    </span>
                                                                    <span className="sign">
                                                                        {getCurrencyData(punk.currency_address).symbol}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                        :
                                        '')
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="partners-section">
                    <h4 className="partner-section-title">- our partners -</h4>
                    <div className="partners-container">
                        <div className="partner-item tron-foundation">
                            <img src={require('~/assets/images/partners/tron-foundation.svg').default} alt="Tron Foundation"/>
                        </div>
                        <div className="partner-item apenft">
                            <img src={require('~/assets/images/partners/apenft.svg').default} alt="APENFT"/>
                        </div>
                        <div className="partner-item poloniex">
                            <img src={require('~/assets/images/partners/poloniex-logo.svg').default} alt="Poloniex"/>
                            <img src={require('~/assets/images/partners/poloniex-name.svg').default}/>
                        </div>
                        <div className="partner-item uswap">
                            <img src={require('~/assets/images/partners/uswap.svg').default} alt="USWAP"/>
                        </div>
                        <div className="partner-item tron-store">
                            <img src={require('~/assets/images/partners/tron-store.png').default} alt="Tron Store"/>
                            <caption>Tron Store</caption>
                        </div>
                        <div className="partner-item primo-gardens">
                            <img src={require('~/assets/images/partners/primo-gardens.png').default} alt="Primo Gardens"/>
                        </div>
                        <div className="partner-item fansforever">
                            <img src={require('~/assets/images/partners/fans.png').default} alt="Fansforever"/>
                        </div>
                        {/* <div className="partner-item tavcompany">
                            <img src={require('~/assets/images/partners/TavLogo.png').default} alt="TavCompany"/>
                        </div> */}
                    </div>
                </section>
                <footer className="local">
                    <div className="links social">
                        <a href={this.CT.statics.socials.telegram_group || '/'} target="_blank" className="link">
                            <J.Image src={require('~/assets/images/interface/social-telegram.png').default} />
                        </a>
                        <a href={this.CT.statics.socials.twitter || '/'} target="_blank" className="link">
                            <J.Image src={require('~/assets/images/interface/social-twitter.png').default} />
                        </a>
                        {this.CT.tooltip(null, 'sociallink_feedbots', <>feed bots</>)}
                        <a className="link" data-tip data-for={'sociallink_feedbots'} onClick={() => this.openFeedBots()}>
                            <J.Image src={require('~/assets/images/interface/social-feedbot.png').default} />
                        </a>
                        {/* <a href={this.CT.statics.socials.medium || '/'} target="_blank" className="link">
                            <J.Image src={require('~/assets/images/interface/social-medium.png').default} />
                        </a>
                        <a href={this.CT.statics.socials.discord || '/'} target="_blank" className="link">
                            <J.Image src={require('~/assets/images/interface/social-discord.png').default} />
                        </a> */}
                    </div>
                    <div className="links pages">
                        <a href={this.CT.statics.socials.tronscan || '/'} target="_blank" className="link">
                            <span>
                                contract
                            </span>
                        </a>
                        <a href={'/forSale'} target="_blank" className="link">
                            <span>
                                market
                            </span>
                        </a>
                    </div>
                </footer>
            </>
        )
    }
}