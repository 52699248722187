import gsap, { TweenMax } from 'gsap';
import $, { Draggable } from 'jquery';

class Watch {
    init(that) {
        // Scroll To Top on Page change
        $('.APP .SCROLLVIEW').scrollTop(0)

        // init dynamics
        // console.log(that.CT.dynamics)
        if (that.state.REFS && that.state.REFS.page && that.state.REFS.page == 'punkpage')
            that.CT.set_dynamics({ ...that.CT.dynamics })
        else that.CT.set_dynamics({
            FILTERS_ATTRS: [],
            FILTERS_ATTR_COUNT: [],
            FILTERS_TYPES: [],
            FILTERS_PRICE_RANGE: {min: 0, max: 100},
            SORT_TITLE: '',
            ACTIVE_TAB: 'MARKET',
        })
    }
    scrollLoad(scrollview, content, callback) {
        $('.APP').find(scrollview).on('scroll', function () {
            $(this).scrollTop() + 200 >= $(scrollview).find(content).height() - $(this).height() && callback()
        })
    }
    scrollToTop(scrollview = 'SCROLLVIEW') {
        $('.APP').find(scrollview).scrollTop(0)
    }
}
export default new Watch();