import { Navbar } from './modules/layout/Navbar'
import { Content } from './modules/layout/Content'
import { Titlebar } from './modules/layout/Titlebar'
import { Footer } from './modules/layout/Footer'

import { Loading } from './modules/object/Loading'
import { Stepbox } from './modules/object/Stepbox'

const C = {
    Navbar,
    Content,
    Titlebar,
    Footer,

    Loading,
    Stepbox,
}
export default C