import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import Logarithmic from './Market/Logarithmic';
import { Watch, Actions, Api } from '~/scripts'
import sorter from 'array-sort'
import C from '~/components';
import J from 'juicy-ui'
import Filtering from './Market/_filtering'

export default class Page extends Component {
    static contextType = BodyContext;
    constructor() {
        super()
        this.state = {
            interf: {
                punksShowingCount: 50,
                activeTab: 'MARKET',
                isLoading_market: false,

                itemsToShow: [],
                selectedItems: [],
                activeFilterAttrs: [],
                activeFilterAttrCount: [],
                activeFilterTypes: [],
                // activeFilterPriceRange: { min: -1, max: -1 },
                activeFilterPriceRange: { min: 0, max: 100 },
                activeSortTitle: '',
                searchId: '',

                listMaxPrice: -1,
                listMinPrice: -1,
            },
            storage: {
                market: null,
                PUNKS: null,
                filter_types: [],
                filter_attrs: [],
                proc_filters: {
                    types: null,
                    attributes: null
                },
            },
        }
        this.logSlider = {}
    }
    componentWillMount() {
        const [CT] = this.context
        this.CT = CT
        CT.set_classing({ route: 'mywallet' })
    }
    async componentDidMount() {
        console.log('component did mount')
        Watch.init(this)
        Watch.scrollLoad('.SCROLLVIEW', '.market', () => { this._sst('interf', { punksShowingCount: this.state.interf.punksShowingCount + 50 }) })
        let connected = await this.CT.wallet.init()
        setInterval(async () => {
            let selectedWallet = await this.CT.wallet.getAddress()
            if (!selectedWallet || selectedWallet == undefined) connected = await this.CT.wallet.init()
        }, 2000);
        // const contract = await this.CT.contract.apply('TPUNKS')
        this.CT.utils.getCurrencies().then(res => this._sst('storage', { currencies: res }))
        this._loadMarket().finally(() => {
            if (this.CT.dynamics.COME_FROM == window.location.pathname) {
                this.filter({
                    types: this.CT.dynamics.FILTERS_TYPES || [],
                    attrs: this.CT.dynamics.FILTERS_ATTRS || [],
                    attrCount: this.CT.dynamics.FILTERS_ATTR_COUNT || [],
                    // priceRange: this.CT.dynamics.FILTERS_PRICE_RANGE || { min: this.state.interf.listMinPrice, max: this.state.interf.listMaxPrice }

                    // priceRange: Object.values(this.CT.dynamics.FILTERS_PRICE_RANGE || { min: -1, max: -1 }).every(item => item > -1) ?
                    priceRange: (this.CT.dynamics.FILTERS_PRICE_RANGE || { min: 0, max: 100 }).min > 0 || (this.CT.dynamics.FILTERS_PRICE_RANGE || { min: 0, max: 100 }).max < 100 ?
                        {
                            // min: this.CT.dynamics.FILTERS_PRICE_RANGE.min < this.state.interf.listMinPrice ? this.state.interf.listMinPrice : this.CT.dynamics.FILTERS_PRICE_RANGE.min,
                            // max: this.CT.dynamics.FILTERS_PRICE_RANGE.max > this.state.interf.listMaxPrice ? this.state.interf.listMaxPrice : this.CT.dynamics.FILTERS_PRICE_RANGE.max
                            // min: this.CT.dynamics.FILTERS_PRICE_RANGE.min < 0 ? 0 : this.CT.dynamics.FILTERS_PRICE_RANGE.min,
                            // max: this.CT.dynamics.FILTERS_PRICE_RANGE.max > 100 ? 100 : this.CT.dynamics.FILTERS_PRICE_RANGE.max
                            minPos: this.CT.dynamics.FILTERS_PRICE_RANGE.min < 0 ? 0 : this.CT.dynamics.FILTERS_PRICE_RANGE.min,
                            maxPos: this.CT.dynamics.FILTERS_PRICE_RANGE.max > 100 ? 100 : this.CT.dynamics.FILTERS_PRICE_RANGE.max,
                            minPrice: this.logSlider.value(this.CT.dynamics.FILTERS_PRICE_RANGE.min < 0 ? 0 : this.CT.dynamics.FILTERS_PRICE_RANGE.min),
                            maxPrice: this.logSlider.value(this.CT.dynamics.FILTERS_PRICE_RANGE.max > 100 ? 100 : this.CT.dynamics.FILTERS_PRICE_RANGE.max)
                        } :
                        {
                            // min: this.state.interf.listMinPrice, max: this.state.interf.listMaxPrice
                            // min: 0, max: 100
                            minPos: 0,
                            maxPos: 100,
                            minPrice: this.state.interf.listMinPrice,
                            maxPrice: this.state.interf.listMaxPrice,
                        },
                })
                this.sort(this.CT.dynamics.SORT_TITLE || '')
            }
            this._sst('interf', { isLoading_market: false })
        })
    }
    _sst(main, obj) {
        this.setState({
            [main]: {
                ...this.state[main],
                ...obj
            }
        })
    }
    _getItemsToShow() {
        let itemsToShow = this.state.interf.selectedItems;
        if (this.state.interf.searchId !== '' && !isNaN(this.state.interf.searchId)) {
            itemsToShow = itemsToShow.filter(item => {
                const punkId = this.state.interf.activeTab === 'MARKET' ? item.punk_id : item.idx;
                return parseInt(punkId) == parseInt(this.state.interf.searchId)
            })
        }
        return itemsToShow;
    }
    _loadMarket() {
        let loadedData = null
        let CT_PUNKS = this.CT.storage.PUNKS

        let market = []
        this._sst('interf', { isLoading_market: true })
        return new Promise(async (resolve, reject) => {
            try {
                setTimeout(async () => {
                    let selectedWallet = await this.CT.wallet.getAddress()
                    !selectedWallet && await this.CT.wallet.init()
                    try {
                        let conutForTypes = {}
                        let countForAttrs = {}
                        let countForAttrCount = {}
                        loadedData = selectedWallet && await Api.get(`/myPunks?wallet=${selectedWallet}`)
                        if (loadedData) {
                            loadedData.map((item, i) => {
                                // add punk details propery to each item
                                item.details = this.CT.storage.PUNKS[item.punk_id];

                                market.push(item)
                                conutForTypes[CT_PUNKS[item.punk_id].type] = conutForTypes[CT_PUNKS[item.punk_id].type] ? conutForTypes[CT_PUNKS[item.punk_id].type] + 1 : 1
                                CT_PUNKS[item.punk_id].attributes.map(attr => countForAttrs[attr] = countForAttrs[attr] ? countForAttrs[attr] + 1 : 1)
                                countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] = countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] ? countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] + 1 : 1
                            })
                            let _proc_filters = this.CT.storage.FILTERS
                            Object.keys(_proc_filters['types']).map(key => {
                                _proc_filters['types'][key]._count = conutForTypes[key]
                            })
                            Object.keys(_proc_filters['attributes']).map(key => {
                                _proc_filters['attributes'][key]._count = countForAttrs[key]
                            })
                            Object.keys(_proc_filters['attributeCount']).map(key => {
                                _proc_filters['attributeCount'][key]._count = countForAttrCount[key]
                            })
                            this._sst('storage', { market, PUNKS: market, proc_filters: _proc_filters })
                        }
                    }
                    catch (err) {
                        console.log(err)
                        this.CT.toast('error', 'server did not respond. try later')
                    }
                    this._sst('storage', { market, PUNKS: market })
                    const pricesArr = market.filter(item => item.market_data.list.isForSale).map(item => item.market_data.list.priceInUsd)
                    let minPrice = Math.floor(Math.min(...pricesArr));
                    let maxPrice = Math.ceil(Math.max(...pricesArr));
                    if (minPrice == maxPrice) {
                        minPrice = 0;
                    }
                    minPrice = 0.9 * minPrice;
                    maxPrice = 1.1 * maxPrice;
                    this._sst('interf', {
                        haveWallet: selectedWallet ? true : false,
                        selectedItems: market,

                        listMinPrice: minPrice, // minus min value 2% because price changes
                        listMaxPrice: maxPrice // pluse max value 2% because price changes 
                    })
                    this.logSlider = new Logarithmic({ minpos: 0, maxpos: 100, minval: minPrice, maxval: maxPrice });
                    resolve(true)
                }, 800);
            } catch (err) {
                resolve('DATA-NOT-LOAD', err)
            }
        })
    }
    _sort(items, sortBy) {
        let sortedItems = [...items];
        if (sortBy === '') {
            sortBy = 'transfer_at'
        }
        sortedItems.sort((a, b) => {
            if (['highest price', 'lowest price'].includes(sortBy)) {
                const aPriceInUsd = a.market_data.list.isForSale ? a.market_data.list.priceInUsd : -1;
                const bPriceInUsd = b.market_data.list.isForSale ? b.market_data.list.priceInUsd : -1;
                if (sortBy == 'highest price') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return bPriceInUsd - aPriceInUsd
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (sortBy == 'lowest price') {

                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return aPriceInUsd - bPriceInUsd
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else if (['highest bid', 'lowest bid'].includes(sortBy)) {
                const aPriceInUsd = a.market_data.bids.length ? Math.max(...a.market_data.bids.map(item => item.priceInUsd)) : -1;
                const bPriceInUsd = b.market_data.bids.length ? Math.max(...b.market_data.bids.map(item => item.priceInUsd)) : -1;
                if (sortBy == 'highest bid') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return bPriceInUsd - aPriceInUsd
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (sortBy == 'lowest bid') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return aPriceInUsd - bPriceInUsd
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else if (['highest rank', 'lowest rank'].includes(sortBy)) {
                const aDetails = this.state.interf.activeTab === 'MARKET' ? a.details : a;
                const bDetails = this.state.interf.activeTab === 'MARKET' ? b.details : b;
                if (sortBy == 'highest rank') {
                    return bDetails.rank - aDetails.rank
                } else if (sortBy == 'lowest rank') {
                    return aDetails.rank - bDetails.rank
                }
            } else if (['highest id', 'lowest id'].includes(sortBy)) {
                const aPunkId = this.state.interf.activeTab === 'MARKET' ? a.punk_id : a.idx;
                const bPunkId = this.state.interf.activeTab === 'MARKET' ? b.punk_id : b.idx;
                if (sortBy == 'highest id') {
                    return bPunkId - aPunkId
                } else if (sortBy == 'lowest id') {
                    return aPunkId - bPunkId
                }
            } else if (sortBy === 'transfer_at') {
                return b.transfer_at - a.transfer_at
            }
        })
        return sortedItems;
    }
    filter(o) {
        // get all currenct tab items
        let allItems = this.state.storage.market


        let itemsToShow = allItems;

        // filter items
        itemsToShow = allItems && allItems.filter((item) => {
            const details = item.details;
            // type filter
            const typeFilterRes = o.types.length > 0 ? o.types.some(type => type == details.type) : true
            // attr filter
            const attrFilterRes = o.attrs.length > 0 ? o.attrs.some(attr => details.attributes.includes(attr)) : true
            // attr count filter
            const attrCountFilterRes = o.attrCount.length > 0 ? o.attrCount.some(count => details.attributes.length === Number(count)) : true
            // price range filter
            let priceRageFilterRes = true;
            if (!(o.priceRange.minPos <= 0 && o.priceRange.maxPos >= 100)) {
                if (!(o.priceRange.minPrice <= this.state.interf.listMinPrice && o.priceRange.maxPrice >= this.state.interf.listMaxPrice)) {
                    if (item.market_data.list.isForSale) {
                        priceRageFilterRes = item.market_data.list.priceInUsd >= o.priceRange.minPrice && item.market_data.list.priceInUsd <= o.priceRange.maxPrice
                    } else {
                        priceRageFilterRes = false;
                    }
                }
            }

            return typeFilterRes && attrFilterRes && attrCountFilterRes && priceRageFilterRes
        })

        // sort items
        const sortBy = this.state.interf.activeSortTitle;
        itemsToShow = this._sort(itemsToShow, sortBy)

        this._sst('interf', {
            punksShowingCount: 50,

            selectedItems: itemsToShow,
            activeFilterAttrs: o.attrs,
            activeFilterAttrCount: o.attrCount,
            activeFilterTypes: o.types,
            activeFilterPriceRange: {
                min: o.priceRange.minPos,
                max: o.priceRange.maxPos
            }
        })

    }
    sort(o) {
        let selectedItems = this.state.interf.selectedItems

        const sortedItems = this._sort(selectedItems, o);

        this._sst('interf', {
            punksShowingCount: 50,

            activeSortTitle: o,

            selectedItems: sortedItems
        })

    }
    search(id) {
        if (!isNaN(id)) {
            this._sst('interf', {
                searchId: id
            })
        }
    }
    openFilter() {
        this.CT.popup.open(null, {
            title: 'FILTER',
            modify: {
                showConfirmButton: false,
                width: 800
            },
            body: <>
                <Filtering
                    init={{
                        attrs: this.state.interf.activeFilterAttrs,
                        attrCount: this.state.interf.activeFilterAttrCount,
                        types: this.state.interf.activeFilterTypes,
                        initPriceRange: this.state.interf.activeFilterPriceRange,
                        min_price: this.state.interf.listMinPrice,
                        max_price: this.state.interf.listMaxPrice,
                        sort_title: this.state.interf.activeSortTitle
                    }}
                    isMarket={this.state.interf.activeTab == 'MARKET'}
                    filters={this.state.storage.proc_filters}
                    onSort={(o) => this.sort(o)}
                    onFilter={(o) => this.filter(o)}
                />
            </>
        })
            .then(async res => console.log('DONE'))
            .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
    }
    __initDynamics() {
        this.CT.set_dynamics({
            FILTERS_ATTRS: this.state.interf.activeFilterAttrs,
            FILTERS_ATTR_COUNT: this.state.interf.activeFilterAttrCount,
            FILTERS_TYPES: this.state.interf.activeFilterTypes,
            FILTERS_PRICE_RANGE: this.state.interf.activeFilterPriceRange,
            SORT_TITLE: this.state.interf.activeSortTitle,
        })
    }
    r_market() {
        const CT = this.CT
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        return (
            !this.state.interf.isLoading_market
                ?
                this._getItemsToShow().length > 0
                    ?
                    this._getItemsToShow().slice(0, this.state.interf.punksShowingCount).map((punk, i) => {
                        punk._bid_highest = null
                        if (punk.market_data.bids.length > 0) {
                            let highestBid = { priceInUsd: 0 }
                            punk.market_data.bids.map(bid => {
                                highestBid = bid.priceInUsd >= highestBid.priceInUsd ? bid : highestBid
                            })
                            punk._bid_highest = highestBid
                        }
                        return <div className="item-" key={i}>
                            <Link className="punk" onClick={() => this.__initDynamics()} to={{ pathname: `detail/Tpunks/${punk.punk_id}`, query: { sender: '/mywallet' } }}>
                                <div className="image">
                                    <J.Image className="--Punk_image" src={this.CT.utils.pis(punk.punk_id)} />
                                </div>
                                <div className="info">
                                    <div className="basic">
                                        <div className="name">
                                            <span>
                                                {punk.punk_id}
                                            </span>
                                        </div>
                                        <div className="type">
                                            <span>
                                                rank {this.CT.storage.ORG_PUNKS[punk.punk_id].rank}
                                            </span>
                                            <span style={{ fontSize: '12px' }}>
                                                {this.CT.storage.ORG_PUNKS[punk.punk_id].type}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="extra">
                                        <div className="price">
                                            <div className="--Price" data-tip data-for={'punk_list_price-' + i} style={{ height: '22px' }}>
                                                {this.state.storage.currencies && punk.market_data.list.minValue && punk.market_data.list.isForSale
                                                    ?
                                                    <>
                                                        <J.Image width="20px" height="20px" src={getCurrencyData(punk.market_data.list.currencyAddress).logo} />&nbsp;
                                                        <span className="number">
                                                            {this.CT.tooltip(null, 'punk_list_price-' + i, <>{(punk.market_data.list.minValue / Math.pow(10, getCurrencyData(punk.market_data.list.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(punk.market_data.list.currencyAddress).symbol}</>)}
                                                            {this.CT.utils.normalizeNum(punk.market_data.list.minValue / Math.pow(10, getCurrencyData(punk.market_data.list.currencyAddress).decimals))}
                                                        </span>
                                                        <span className="sign">
                                                            {getCurrencyData(punk.market_data.list.currencyAddress).symbol}
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            <div className="--Price -sub" data-tip data-for={'punk_bid_price-' + i}>
                                                {this.state.storage.currencies && punk._bid_highest
                                                    ?
                                                    <>
                                                        <p>bid</p>&nbsp;
                                                        <J.Image width="15px" height="15px" src={getCurrencyData(punk._bid_highest.currencyAddress).logo} />&nbsp;
                                                        {this.CT.tooltip(null, 'punk_bid_price-' + i, <>{(punk._bid_highest.value / Math.pow(10, getCurrencyData(punk._bid_highest.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(punk._bid_highest.currencyAddress).symbol}</>)}
                                                        <span className="number" style={{ opacity: '0.5' }}>
                                                            {this.CT.utils.normalizeNum(punk._bid_highest.value / Math.pow(10, getCurrencyData(punk._bid_highest.currencyAddress).decimals))}
                                                        </span>
                                                        <span className="sign">
                                                            {getCurrencyData(punk._bid_highest.currencyAddress).symbol}
                                                        </span>
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    })
                    :
                    (
                        this.state.interf.haveWallet
                            ?
                            'there is no any punk to show'
                            :
                            <div className="problem">
                                <p>wallet not found.</p>&nbsp;{window.tronWeb && <J.Btn title="CONNECT WALLET" iconName="ri-wallet-2-fill" type="simple" onClick={() => this._connectWallet()} />}
                            </div>
                    )
                :
                <C.Loading />
        )
    }
    async _connectWallet() {
        let action = null
        try {
            action = window.tronWeb && await window.tronWeb.request({
                method: 'tron_requestAccounts'
            })
            action ? (action.code != 4001 ? this.CT.toast('success', 'Wallet connected, refresh page.') : this.CT.toast('error', 'Wallet not connected')) : this.CT.toast('warning', 'First make sure tronlink is active on your browser')
        }
        catch (err) {
            this.CT.toast('error', err)
        }
    }
    render() {
        const CT = this.CT
        const { interf, storage } = this.state
        return (
            <>
                <div className="market">
                    <div className="head">
                        <div className="title">
                            <span>
                                MY WALLET {this.state.storage.market && <strong style={{ fontSize: '24px' }}>{'(' + this.state.storage.market.length + 'punks)'}</strong>}
                            </span>
                        </div>
                        {storage.market &&
                            <div className="actions">
                                <J.Input placeHolder="punk id" iconName="ri-search-line" value={this.state.interf.searchId} onChange={(e) => this.search(e.target.value)} />
                                <J.Btn title="FILTER" type="simple" iconName="ri-equalizer-line" onClick={() => this.openFilter()} />
                            </div>
                        }
                    </div>
                    <div className="list">
                        {interf.activeTab == 'MARKET' && this.r_market()}
                    </div>
                </div>
            </>
        )
    }
}