import { punks } from './punks'
const getFilters = () => {
  let types = {}
  let attrs = {}
  punks.map((punk, i) => {
    !types[punk.type] ? types[punk.type] = { title: punk.type, count: 1 } : types[punk.type] = { title: punk.type, count: types[punk.type].count + 1 }
    punk.attributes.map(attr => {
      !attrs[attr] ? attrs[attr] = { title: attr, count: 1 } : attrs[attr] = { title: attr, count: attrs[attr].count + 1 }
    })
  })
  console.log(types)
  console.log(attrs)
}
export const filters = {
  types: {
    "Female": {
      "title": "Female",
      "count": 3839
    },
    "Male": {
      "title": "Male",
      "count": 6029
    },
    "Zombie": {
      "title": "Zombie",
      "count": 88
    },
    "Ape": {
      "title": "Ape",
      "count": 24
    },
    "Alien": {
      "title": "Alien",
      "count": 9
    },
    "JustinSun": {
      "title": "JustinSun",
      "count": 11
    }
  },
  attributes: {
    "Green Eye Shadow": {
      "title": "Green Eye Shadow",
      "count": 271
    },
    "Earring": {
      "title": "Earring",
      "count": 2453
    },
    "Blonde Bob": {
      "title": "Blonde Bob",
      "count": 147
    },
    "Smile": {
      "title": "Smile",
      "count": 239
    },
    "Mohawk": {
      "title": "Mohawk",
      "count": 441
    },
    "Wild Hair": {
      "title": "Wild Hair",
      "count": 447
    },
    "Nerd Glasses": {
      "title": "Nerd Glasses",
      "count": 571
    },
    "Pipe": {
      "title": "Pipe",
      "count": 317
    },
    "Big Shades": {
      "title": "Big Shades",
      "count": 534
    },
    "Goat": {
      "title": "Goat",
      "count": 294
    },
    "Purple Eye Shadow": {
      "title": "Purple Eye Shadow",
      "count": 262
    },
    "Half Shaved": {
      "title": "Half Shaved",
      "count": 147
    },
    "Do-rag": {
      "title": "Do-rag",
      "count": 300
    },
    "Wild White Hair": {
      "title": "Wild White Hair",
      "count": 136
    },
    "Spots": {
      "title": "Spots",
      "count": 124
    },
    "Clown Eyes Blue": {
      "title": "Clown Eyes Blue",
      "count": 384
    },
    "Messy Hair": {
      "title": "Messy Hair",
      "count": 460
    },
    "Luxurious Beard": {
      "title": "Luxurious Beard",
      "count": 286
    },
    "Clown Nose": {
      "title": "Clown Nose",
      "count": 211
    },
    "Police Cap": {
      "title": "Police Cap",
      "count": 203
    },
    "Big Beard": {
      "title": "Big Beard",
      "count": 146
    },
    "Blue Eye Shadow": {
      "title": "Blue Eye Shadow",
      "count": 266
    },
    "Black Lipstick": {
      "title": "Black Lipstick",
      "count": 617
    },
    "Clown Eyes Green": {
      "title": "Clown Eyes Green",
      "count": 382
    },
    "Straight Hair Dark": {
      "title": "Straight Hair Dark",
      "count": 148
    },
    "Blonde Short": {
      "title": "Blonde Short",
      "count": 129
    },
    "Purple Lipstick": {
      "title": "Purple Lipstick",
      "count": 655
    },
    "Straight Hair Blonde": {
      "title": "Straight Hair Blonde",
      "count": 144
    },
    "Pilot Helmet": {
      "title": "Pilot Helmet",
      "count": 54
    },
    "Hot Lipstick": {
      "title": "Hot Lipstick",
      "count": 696
    },
    "Regular Shades": {
      "title": "Regular Shades",
      "count": 526
    },
    "Stringy Hair": {
      "title": "Stringy Hair",
      "count": 463
    },
    "Small Shades": {
      "title": "Small Shades",
      "count": 376
    },
    "Frown": {
      "title": "Frown",
      "count": 261
    },
    "Eye Mask": {
      "title": "Eye Mask",
      "count": 293
    },
    "Muttonchops": {
      "title": "Muttonchops",
      "count": 303
    },
    "Bandana": {
      "title": "Bandana",
      "count": 481
    },
    "Horned Rim Glasses": {
      "title": "Horned Rim Glasses",
      "count": 535
    },
    "Crazy Hair": {
      "title": "Crazy Hair",
      "count": 414
    },
    "Classic Shades": {
      "title": "Classic Shades",
      "count": 502
    },
    "Handlebars": {
      "title": "Handlebars",
      "count": 263
    },
    "Mohawk Dark": {
      "title": "Mohawk Dark",
      "count": 429
    },
    "Dark Hair": {
      "title": "Dark Hair",
      "count": 157
    },
    "Peak Spike": {
      "title": "Peak Spike",
      "count": 303
    },
    "Normal Beard Black": {
      "title": "Normal Beard Black",
      "count": 289
    },
    "Cap": {
      "title": "Cap",
      "count": 350
    },
    "VR": {
      "title": "VR",
      "count": 332
    },
    "Frumpy Hair": {
      "title": "Frumpy Hair",
      "count": 442
    },
    "Cigarette": {
      "title": "Cigarette",
      "count": 959
    },
    "Normal Beard": {
      "title": "Normal Beard",
      "count": 291
    },
    "Red Mohawk": {
      "title": "Red Mohawk",
      "count": 147
    },
    "Shaved Head": {
      "title": "Shaved Head",
      "count": 300
    },
    "Chinstrap": {
      "title": "Chinstrap",
      "count": 281
    },
    "Mole": {
      "title": "Mole",
      "count": 644
    },
    "Knitted Cap": {
      "title": "Knitted Cap",
      "count": 419
    },
    "Fedora": {
      "title": "Fedora",
      "count": 186
    },
    "Shadow Beard": {
      "title": "Shadow Beard",
      "count": 526
    },
    "Straight Hair": {
      "title": "Straight Hair",
      "count": 151
    },
    "Hoodie": {
      "title": "Hoodie",
      "count": 259
    },
    "Eye Patch": {
      "title": "Eye Patch",
      "count": 461
    },
    "Headband": {
      "title": "Headband",
      "count": 396
    },
    "Cowboy Hat": {
      "title": "Cowboy Hat",
      "count": 142
    },
    "Tassle Hat": {
      "title": "Tassle Hat",
      "count": 178
    },
    "3D Glasses": {
      "title": "3D Glasses",
      "count": 287
    },
    "Mustache": {
      "title": "Mustache",
      "count": 288
    },
    "Vape": {
      "title": "Vape",
      "count": 272
    },
    "Choker": {
      "title": "Choker",
      "count": 48
    },
    "Pink With Hat": {
      "title": "Pink With Hat",
      "count": 95
    },
    "Welding Goggles": {
      "title": "Welding Goggles",
      "count": 86
    },
    "Vampire Hair": {
      "title": "Vampire Hair",
      "count": 147
    },
    "Mohawk Thin": {
      "title": "Mohawk Thin",
      "count": 441
    },
    "Tiara": {
      "title": "Tiara",
      "count": 55
    },
    "Front Beard Dark": {
      "title": "Front Beard Dark",
      "count": 260
    },
    "Cap Forward": {
      "title": "Cap Forward",
      "count": 254
    },
    "Gold Chain": {
      "title": "Gold Chain",
      "count": 169
    },
    "Purple Hair": {
      "title": "Purple Hair",
      "count": 165
    },
    "Beanie": {
      "title": "Beanie",
      "count": 44
    },
    "Clown Hair Green": {
      "title": "Clown Hair Green",
      "count": 148
    },
    "Pigtails": {
      "title": "Pigtails",
      "count": 94
    },
    "Silver Chain": {
      "title": "Silver Chain",
      "count": 156
    },
    "Front Beard": {
      "title": "Front Beard",
      "count": 273
    },
    "Rosy Cheeks": {
      "title": "Rosy Cheeks",
      "count": 128
    },
    "Orange Side": {
      "title": "Orange Side",
      "count": 68
    },
    "Wild Blonde": {
      "title": "Wild Blonde",
      "count": 144
    },
    "Buck Teeth": {
      "title": "Buck Teeth",
      "count": 78
    },
    "Top Hat": {
      "title": "Top Hat",
      "count": 115
    },
    "Medical Mask": {
      "title": "Medical Mask",
      "count": 175
    }
  },
  attributeCount: {
    '0': { count: 8, },
    '1': { count: 343, },
    '2': { count: 3554, },
    '3': { count: 4499, },
    '4': { count: 1419, },
    '5': { count: 165, },
    '6': { count: 11, },
    '7': { count: 1 },
  }
}