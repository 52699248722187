import React, { useContext } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { BodyContext } from '~/context/BodyContext.js'
import { PrivateRoute } from './PrivateRoute'
import C from '~/components'
// 
import Home from '~/pages/guest/Home'
import Apps from '~/pages/guest/Apps'
import About from '~/pages/guest/About'
import MyWallet from '~/pages/guest/MyWallet'
import Market from '~/pages/guest/Market'
import PunkPage from '~/pages/guest/Market/PunkPage'
// 

const R_User = props => {
    const [setting] = useContext(BodyContext);
    const base_path = props.match.path;
    return (
        <div className={"route " + setting.classing.route}>
            <div className="page">
                <div className="c-">
                    <C.Navbar />
                    {/* <Titlebar /> */}
                    <C.Content>
                        User
                    </C.Content>
                </div>
            </div>
        </div>
    );
}
const R_Guest = props => {
    const [setting] = useContext(BodyContext);
    const base_path = props.match.path === '/' ? '' : props.match.path;
    return (
        <div className={"route " + setting.classing.route + ` ${setting.nightmode ? '-nightmode' : ''}`}>
            <div className="page">
                <div className="c-">
                    <C.Navbar />
                    {/* <Titlebar /> */}
                    <C.Content className="MAIN">
                        <Switch>
                            <Route exact path={`${base_path}/`} component={Home} />
                            <Route path={`${base_path}/apps`} component={Apps} />
                            <Route path={`${base_path}/about`} component={About} />
                            <Route path={`${base_path}/mywallet`} component={MyWallet} />
                            <Route path={`${base_path}/forSale`} render={() => <>
                                <Route exact path={`${base_path}/forSale`} component={Market} />
                                <Route path={`${base_path}/forSale/:id`} render={(props) => <Redirect to={`/detail/Tpunks/${props.match.params.id}`} />} />
                                <Route path={`${base_path}/forSale/:id/*`} render={() => <Redirect to={`/`} />} />
                            </>
                            } />
                            <Route path={`${base_path}/detail`} render={() => <>
                                <Route exact path={`${base_path}/detail`} render={() => <Redirect to={`/`} />} />
                                <Route path={`${base_path}/detail/Tpunks`} render={() => <>
                                    <Route exact path={`${base_path}/detail/Tpunks`} render={() => <Redirect to={`/forSale`} />} />
                                    <Route path={`${base_path}/detail/Tpunks/:id`} component={PunkPage} />
                                    <Route path={`${base_path}/detail/Tpunks/:id/*`} render={() => <Redirect to={`/forSale`} />} />
                                </>} />
                            </>} />
                            <Redirect path={`*`} to={`/`} />
                            {setting.authRedirection}
                        </Switch>
                    </C.Content>
                </div>
            </div>
        </div>
    );
}
const R_Admin = props => {
    const [setting] = useContext(BodyContext);
    return (
        <div className={"route " + setting.route}>
            <div className="page">
                <div className="c-">
                    <C.Content>
                        Admin
                    </C.Content>
                </div>
            </div>
        </div>
    );
}

const Routing = {}
Routing.user = R_User;
Routing.guest = R_Guest;
Routing.admin = R_Admin;
export default Routing;