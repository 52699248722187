import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import { Watch, Actions } from '~/scripts'
import C from '~/components';
import J from 'juicy-ui'

export default class Page extends Component {
    static contextType = BodyContext;
    constructor() {
        super()
        this.state = {
            interf: {},
            storage: {}
        }
    }
    componentWillMount() {
        const [CT] = this.context
        this.CT = CT
        CT.set_classing({ route: 'apps' })
    }
    componentDidMount() {
        Watch.init(this)
    }

    render() {
        const { interf, storage } = this.state
        return (
            <>
                <div className="apps">
                    <div className="title">
                        <span>
                            APPS
                        </span>
                    </div>
                    <div className="list">
                        <Link className="app">
                            <div className="image">
                                <J.Image src={require('~/assets/images/defaults/app-icon.jpg').default} />
                            </div>
                            <div className="name">
                                <span>
                                    Prflr
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}