import TronGrid from 'trongrid'
import TronWeb from 'tronweb'

const tronWeb = new TronWeb({
    fullHost: 'https://api.shasta.trongrid.io'
});


class Wallet {
    constructor() {
        this.TronGrid = null
        this.TronWeb = null
        this.GlobalTronWeb = tronWeb
        this.Trx = null
        this.Wallet = null
    }
    async connect() {
        this.Trx = window.tronWeb ? await window.tronWeb.trx : null
        this.TronGrid = await new TronGrid(tronWeb)
        this.TronWeb = window.tronWeb && await window.tronWeb
        return this.TronWeb || null
        // if (window.tronWeb && (window.tronWeb.fullNode.host == "https://api.trongrid.io" || window.tronWeb.fullNode.host == "https://api.tronstack.io") && window.tronWeb.defaultAddress.base58) {
        // } else {
        // }
    }
    async getAddress() {
        try {
            const wallet = window.tronWeb && await window.tronWeb.defaultAddress.base58
            return wallet
        }
        catch (err) {
            console.log(err)
        }
    }
}
export default Wallet