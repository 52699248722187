import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import Logarithmic from './Market/Logarithmic';
import { Watch, Actions, Api } from '~/scripts'
import sorter from 'array-sort'
import C from '~/components';
import J from 'juicy-ui'
import Filtering from './Market/_filtering'

export default class Page extends Component {
    static contextType = BodyContext;
    constructor() {
        super()
        this.state = {
            interf: {
                punksShowingCount: 50,
                activeTab: 'MARKET',
                isLoading_market: false,
                delayOnFilter: 1000,
                itemsToShow: [],
                selectedItems: [],
                activeFilterAttrs: [],
                activeFilterAttrCount: [],
                activeFilterTypes: [],
                // activeFilterPriceRange: { min: -1, max: -1 },
                activeFilterPriceRange: { min: 0, max: 100 },
                activeSortTitle: '',
                searchId: '',
                history_activeBox: 'SOLD',

                listMaxPrice: -1,
                listMinPrice: -1,
            },
            storage: {
                market: [],
                PUNKS: [],
                filter_types: [],
                filter_attrs: [],
                proc_filters: {
                    types: null,
                    attributes: null
                },
            },
        }
        this.logSlider = {}
    }
    componentWillMount() {
        const [CT] = this.context
        this.CT = CT
        CT.set_classing({ route: 'market' })
    }
    async componentDidMount() {
        Watch.init(this)
        Watch.scrollLoad('.SCROLLVIEW', '.market', () => { this._sst('interf', { punksShowingCount: this.state.interf.punksShowingCount + 50 }) })
        let connected = await this.CT.wallet.init()
        setInterval(async () => {
            let selectedWallet = await this.CT.wallet.getAddress()
            if (!selectedWallet || selectedWallet == undefined) connected = await this.CT.wallet.init()
        }, 2000);
        this.CT.utils.getCurrencies().then(res => this._sst('storage', { currencies: res }))
        this._sst('interf', { isLoading_market: true })
        try {
            await this.setActiveTab(this.CT.dynamics.ACTIVE_TAB || 'MARKET')
        } catch (err) {
            console.error(err)
        }
        this._loadMarket().finally(() => {
            if (this.CT.dynamics.COME_FROM == window.location.pathname) {
                this.filter({
                    types: this.CT.dynamics.FILTERS_TYPES || [],
                    attrs: this.CT.dynamics.FILTERS_ATTRS || [],
                    attrCount: this.CT.dynamics.FILTERS_ATTR_COUNT || [],
                    // priceRange: this.CT.dynamics.FILTERS_PRICE_RANGE || { min: this.state.interf.listMinPrice, max: this.state.interf.listMaxPrice }
                    // priceRange: this.CT.dynamics.FILTERS_PRICE_RANGE ?
                    //     {
                    //         min: this.CT.dynamics.FILTERS_PRICE_RANGE.min < this.state.interf.listMinPrice ? this.state.interf.listMinPrice : this.CT.dynamics.FILTERS_PRICE_RANGE.min,
                    //         max: this.CT.dynamics.FILTERS_PRICE_RANGE.max > this.state.interf.listMaxPrice ? this.state.interf.listMaxPrice : this.CT.dynamics.FILTERS_PRICE_RANGE.max
                    //     } :
                    //     {
                    //         min: this.state.interf.listMinPrice, max: this.state.interf.listMaxPrice
                    //     },
                    priceRange: (this.CT.dynamics.FILTERS_PRICE_RANGE || { min: 0, max: 100 }).min > 0 || (this.CT.dynamics.FILTERS_PRICE_RANGE || { min: 0, max: 100 }).max < 100 ?
                        {
                            minPos: this.CT.dynamics.FILTERS_PRICE_RANGE.min < 0 ? 0 : this.CT.dynamics.FILTERS_PRICE_RANGE.min,
                            maxPos: this.CT.dynamics.FILTERS_PRICE_RANGE.max > 100 ? 100 : this.CT.dynamics.FILTERS_PRICE_RANGE.max,
                            minPrice: this.logSlider.value(this.CT.dynamics.FILTERS_PRICE_RANGE.min < 0 ? 0 : this.CT.dynamics.FILTERS_PRICE_RANGE.min),
                            maxPrice: this.logSlider.value(this.CT.dynamics.FILTERS_PRICE_RANGE.max > 100 ? 100 : this.CT.dynamics.FILTERS_PRICE_RANGE.max)
                        } :
                        {
                            // min: this.state.interf.listMinPrice, max: this.state.interf.listMaxPrice
                            minPos: 0,
                            maxPos: 100,
                            minPrice: this.state.interf.listMinPrice,
                            maxPrice: this.state.interf.listMaxPrice,
                        },
                })
                this.sort(this.state.interf.activeTab === 'MARKET' ? this.CT.dynamics.SORT_TITLE || 'lowest price' : this.CT.dynamics.SORT_TITLE || '')
            } else {
                this.state.interf.activeTab === 'MARKET' && this.sort('lowest price')
            }
            this._sst('interf', { isLoading_market: false })
        })
        this._loadRecentlyEvents()
    }
    _sst(main, obj, callback) {
        this.setState({
            [main]: {
                ...this.state[main],
                ...obj
            }
        }, () => callback ? callback() : null)
    }
    _getItemsToShow() {
        let itemsToShow = this.state.interf.selectedItems;
        if (this.state.interf.searchId !== '' && !isNaN(this.state.interf.searchId)) {
            itemsToShow = itemsToShow.filter(item => {
                const punkId = this.state.interf.activeTab === 'MARKET' ? item.punk_id : item.idx;
                return parseInt(punkId) == parseInt(this.state.interf.searchId)
            })
        }
        return itemsToShow;
    }
    _loadMarket() {
        let loadedData = null
        let CT_PUNKS = this.CT.storage.PUNKS
        let market = []
        return new Promise(async (resolve, reject) => {
            this._sst('interf', { isLoading_market: true })
            try {
                let conutForTypes = {}
                let countForAttrs = {}
                let countForAttrCount = {}
                loadedData = await Api.get(`/allMarketData`)
                loadedData.map((item, i) => {
                    if (item.market_data.list.isForSale || item.market_data.bids.length > 0) {
                        // add punk details propery to each item
                        item.details = this.CT.storage.PUNKS[item.punk_id];

                        market.push(item)
                        conutForTypes[CT_PUNKS[item.punk_id].type] = conutForTypes[CT_PUNKS[item.punk_id].type] ? conutForTypes[CT_PUNKS[item.punk_id].type] + 1 : 1
                        CT_PUNKS[item.punk_id].attributes.map(attr => countForAttrs[attr] = countForAttrs[attr] ? countForAttrs[attr] + 1 : 1)
                        countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] = countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] ? countForAttrCount[CT_PUNKS[item.punk_id].attributes.length] + 1 : 1
                    }
                })
                let _proc_filters = this.CT.storage.FILTERS

                Object.keys(_proc_filters['types']).map(key => {
                    _proc_filters['types'][key]._count = conutForTypes[key]
                })
                Object.keys(_proc_filters['attributes']).map(key => {
                    _proc_filters['attributes'][key]._count = countForAttrs[key]
                })
                Object.keys(_proc_filters['attributeCount']).map(key => {
                    _proc_filters['attributeCount'][key]._count = countForAttrCount[key]
                })

                this._sst('storage', { market, PUNKS: market, proc_filters: _proc_filters })
                const pricesArr = this.state.storage.market.filter(item => item.market_data.list.isForSale).map(item => item.market_data.list.priceInUsd)
                let minPrice = Math.floor(Math.min(...pricesArr));
                let maxPrice = Math.ceil(Math.max(...pricesArr));
                if (minPrice == maxPrice) {
                    minPrice = 0;
                }
                minPrice = 0.9 * minPrice;
                maxPrice = 1.1 * maxPrice;
                this._sst('interf', {
                    selectedItems: this.state.interf.activeTab === 'MARKET' ? market : CT_PUNKS,

                    listMinPrice: minPrice,
                    listMaxPrice: maxPrice
                })

                this.logSlider = new Logarithmic({ minpos: 0, maxpos: 100, minval: minPrice, maxval: maxPrice });
                resolve(true)
            } catch (err) {
                this.CT.toast('error', 'server did not respond. try later##')
                console.error('server did not respond. try later', err)
                resolve('DATA-NOT-LOAD', err)
            }
        })
    }
    _loadRecentlyEvents() {
        let recentlyEvents = null
        return new Promise(async (resolve, reject) => {
            try {
                recentlyEvents = await Api.get(`/recentlyEvents`)
                this._sst('storage', { recentlyEvents })
                resolve(recentlyEvents)
            }
            catch (err) {
                this.CT.toast('error', 'server did not respond. try later')
                reject('DATA-NOT-LOAD', err)
            }
        })
    }
    async setActiveTab(tab) {
        return new Promise((resolve, reject) => {
            try {
                this._sst('interf', {
                    activeTab: tab,
                    punksShowingCount: 50,
                    selectedItems: tab === 'MARKET' ? this.state.storage.market : this.CT.storage.PUNKS,
                    activeFilterAttrs: [],
                    activeFilterAttrCount: [],
                    activeFilterTypes: [],
                    // activeFilterPriceRange: { min: -1, max: -1 },
                    activeFilterPriceRange: { min: 0, max: 100 },
                    activeSortTitle: '',
                    searchId: '',
                    history_activeBox: tab == 'HISTORY' ? this.CT.dynamics.HISTORY_ACTIVEBOX || 'LISTED' : this.state.interf.history_activeBox
                }, () => {
                    this.state.interf.activeTab === 'MARKET' ? this.sort('lowest price') : this.sort('')
                    resolve()
                })
            } catch (err) {
                reject(err)
            }
        })
    }
    _sort(items, sortBy) {
        let sortedItems = [...items];

        if (sortBy === '') {
            sortBy = this.state.interf.activeTab === 'MARKET' ? 'lowest price' : 'lowest id';
        }
        sortedItems.sort((a, b) => {
            if (['highest price', 'lowest price'].includes(sortBy)) {
                const aPriceInUsd = a.market_data.list.isForSale ? a.market_data.list.priceInUsd : -1;
                const bPriceInUsd = b.market_data.list.isForSale ? b.market_data.list.priceInUsd : -1;
                if (sortBy == 'highest price') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return bPriceInUsd - aPriceInUsd
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (sortBy == 'lowest price') {

                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return aPriceInUsd - bPriceInUsd
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else if (['highest bid', 'lowest bid'].includes(sortBy)) {
                const aPriceInUsd = a.market_data.bids.length ? Math.max(...a.market_data.bids.map(item => item.priceInUsd)) : -1;
                const bPriceInUsd = b.market_data.bids.length ? Math.max(...b.market_data.bids.map(item => item.priceInUsd)) : -1;
                if (sortBy == 'highest bid') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return bPriceInUsd - aPriceInUsd
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (sortBy == 'lowest bid') {
                    if (aPriceInUsd > -1 && bPriceInUsd > -1) {
                        return aPriceInUsd - bPriceInUsd
                    } else if (bPriceInUsd > -1 && !(aPriceInUsd > -1)) {
                        return 1;
                    } else if (aPriceInUsd > -1 && !(bPriceInUsd > -1)) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else if (['highest rank', 'lowest rank'].includes(sortBy)) {
                const aDetails = this.state.interf.activeTab === 'MARKET' ? a.details : a;
                const bDetails = this.state.interf.activeTab === 'MARKET' ? b.details : b;
                if (sortBy == 'highest rank') {
                    return bDetails.rank - aDetails.rank
                } else if (sortBy == 'lowest rank') {
                    return aDetails.rank - bDetails.rank
                }
            } else if (['highest id', 'lowest id'].includes(sortBy)) {
                const aPunkId = this.state.interf.activeTab === 'MARKET' ? a.punk_id : a.idx;
                const bPunkId = this.state.interf.activeTab === 'MARKET' ? b.punk_id : b.idx;
                if (sortBy == 'highest id') {
                    return bPunkId - aPunkId
                } else if (sortBy == 'lowest id') {
                    return aPunkId - bPunkId
                }
            }
        })
        return sortedItems;
    }
    filter(o) {
        // get all currenct tab items
        let isMarket = this.state.interf.activeTab == 'MARKET'
        let allItems = isMarket ? this.state.storage.market : this.CT.storage.PUNKS;

        let itemsToShow = allItems;

        // filter items
        itemsToShow = allItems && allItems.filter((item) => {
            const details = this.state.interf.activeTab === 'MARKET' ? item.details : item;
            // type filter
            const typeFilterRes = o.types.length > 0 ? o.types.some(type => type == details.type) : true
            // attr filter
            const attrFilterRes = o.attrs.length > 0 ? o.attrs.some(attr => details.attributes.includes(attr)) : true
            // attr count filter
            const attrCountFilterRes = o.attrCount.length > 0 ? o.attrCount.some(count => details.attributes.length === Number(count)) : true
            // price range filter
            let priceRageFilterRes = true;
            // if (this.state.interf.activeTab === 'MARKET' && Object.values(o.priceRange).every(item => item > -1)) {
            //     if (!(o.priceRange.min <= this.state.interf.listMinPrice && o.priceRange.max >= this.state.interf.listMaxPrice)) {
            //         if (item.market_data.list.isForSale) {
            //             priceRageFilterRes = item.market_data.list.priceInUsd >= o.priceRange.min && item.market_data.list.priceInUsd <= o.priceRange.max
            //         } else {
            //             priceRageFilterRes = false;
            //         }
            //     }
            // }

            if (this.state.interf.activeTab === 'MARKET' && !(o.priceRange.minPos <= 0 && o.priceRange.maxPos >= 100)) {
                if (!(o.priceRange.minPrice <= this.state.interf.listMinPrice && o.priceRange.maxPrice >= this.state.interf.listMaxPrice)) {
                    if (item.market_data.list.isForSale) {
                        priceRageFilterRes = item.market_data.list.priceInUsd >= o.priceRange.minPrice && item.market_data.list.priceInUsd <= o.priceRange.maxPrice
                    } else {
                        priceRageFilterRes = false;
                    }
                }
            }

            return typeFilterRes && attrFilterRes && attrCountFilterRes && priceRageFilterRes
        })

        // sort items
        const sortBy = this.state.interf.activeSortTitle;
        itemsToShow = this._sort(itemsToShow, sortBy)

        this._sst('interf', {
            punksShowingCount: 50,

            selectedItems: itemsToShow,
            activeFilterAttrs: o.attrs,
            activeFilterAttrCount: o.attrCount,
            activeFilterTypes: o.types,
            activeFilterPriceRange: {
                min: o.priceRange.minPos,
                max: o.priceRange.maxPos
            }
        })
    }
    sort(o) {
        let selectedItems = this.state.interf.selectedItems

        const sortedItems = this._sort(selectedItems, o);
        this._sst('interf', {
            punksShowingCount: 50,

            activeSortTitle: o,

            selectedItems: sortedItems
        })
    }
    search(id) {
        if (!isNaN(id)) {
            this._sst('interf', {
                searchId: id
            })
        }
    }
    openFilter() {
        this.CT.popup.open(null, {
            title: 'FILTER',
            modify: {
                showConfirmButton: false,
                width: 800,
            },
            body: <>
                <Filtering
                    init={{
                        attrs: this.state.interf.activeFilterAttrs,
                        attrCount: this.state.interf.activeFilterAttrCount,
                        types: this.state.interf.activeFilterTypes,
                        initPriceRange: this.state.interf.activeFilterPriceRange,
                        min_price: this.state.interf.listMinPrice,
                        max_price: this.state.interf.listMaxPrice,
                        sort_title: this.state.interf.activeSortTitle,
                    }}
                    isMarket={this.state.interf.activeTab == 'MARKET'}
                    filters={this.state.storage.proc_filters}
                    onSort={(o) => this.sort(o)}
                    onFilter={(o) => this.filter(o)}
                />
            </>
        })
            .then(async res => console.log('DONE'))
            .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
    }
    __initDynamics() {
        this.CT.set_dynamics({
            FILTERS_ATTRS: this.state.interf.activeFilterAttrs,
            FILTERS_ATTR_COUNT: this.state.interf.activeFilterAttrCount,
            FILTERS_TYPES: this.state.interf.activeFilterTypes,
            FILTERS_PRICE_RANGE: this.state.interf.activeFilterPriceRange,
            SORT_TITLE: this.state.interf.activeSortTitle,
            ACTIVE_TAB: this.state.interf.activeTab,
            HISTORY_ACTIVEBOX: this.state.interf.history_activeBox,
        })
    }
    r_market() {
        const CT = this.CT
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        return (
            <div className="list">
                {!this.state.interf.isLoading_market
                    ?
                    this._getItemsToShow()
                        ?
                        this._getItemsToShow().slice(0, this.state.interf.punksShowingCount).map((punk, i) => {
                            punk._bid_highest = null
                            if (punk.market_data.bids.length > 0) {
                                let highestBid = { priceInUsd: 0 }
                                punk.market_data.bids.map(bid => {
                                    highestBid = bid.priceInUsd >= highestBid.priceInUsd ? bid : highestBid
                                })
                                punk._bid_highest = highestBid
                            }
                            return <div className="item-" key={i}>
                                <Link className="punk" onClick={() => this.__initDynamics()} to={`detail/Tpunks/${punk.punk_id}`}>
                                    <div className="image">
                                        <J.Image className="--Punk_image" src={this.CT.utils.pis(punk.punk_id)} />
                                    </div>
                                    <div className="info">
                                        <div className="basic">
                                            <div className="name">
                                                <span>
                                                    {punk.punk_id}
                                                </span>
                                            </div>
                                            <div className="type">
                                                <span>
                                                    rank {this.CT.storage.ORG_PUNKS[punk.punk_id].rank}
                                                </span>
                                                <span style={{ fontSize: '12px' }}>
                                                    {this.CT.storage.ORG_PUNKS[punk.punk_id].type}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="extra">
                                            <div className="price">
                                                <div className="--Price" data-tip data-for={'punk_list_price-' + i} style={{ height: '22px' }}>
                                                    {this.state.storage.currencies && punk.market_data.list.minValue && punk.market_data.list.isForSale
                                                        ?
                                                        <>
                                                            <J.Image width="20px" height="20px" src={getCurrencyData(punk.market_data.list.currencyAddress).logo} />&nbsp;
                                                            <span className="number">
                                                                {this.CT.tooltip(null, 'punk_list_price-' + i, <>{(punk.market_data.list.minValue / Math.pow(10, getCurrencyData(punk.market_data.list.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(punk.market_data.list.currencyAddress).symbol}</>)}
                                                                {this.CT.utils.normalizeNum(punk.market_data.list.minValue / Math.pow(10, getCurrencyData(punk.market_data.list.currencyAddress).decimals))}
                                                            </span>
                                                            <span className="sign">
                                                                {getCurrencyData(punk.market_data.list.currencyAddress).symbol}
                                                            </span>
                                                            <br />
                                                            <br />
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div className="--Price -sub" data-tip data-for={'punk_bid_price-' + i}>
                                                    {this.state.storage.currencies && punk._bid_highest
                                                        ?
                                                        <>
                                                            <p>bid</p>&nbsp;
                                                            <J.Image width="15px" height="15px" src={getCurrencyData(punk._bid_highest.currencyAddress).logo} />&nbsp;
                                                            {this.CT.tooltip(null, 'punk_bid_price-' + i, <>{(punk._bid_highest.value / Math.pow(10, getCurrencyData(punk._bid_highest.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(punk._bid_highest.currencyAddress).symbol}</>)}
                                                            <span className="number" style={{ opacity: '0.5' }}>
                                                                {this.CT.utils.normalizeNum(punk._bid_highest.value / Math.pow(10, getCurrencyData(punk._bid_highest.currencyAddress).decimals))}
                                                            </span>
                                                            <span className="sign">
                                                                {getCurrencyData(punk._bid_highest.currencyAddress).symbol}
                                                            </span>
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                        :
                        'NO DATA'
                    :
                    <C.Loading />}
            </div>
        )
    }
    r_alltpunks() {
        const CT = this.CT
        const { interf, storage } = this.state
        const punks = this._getItemsToShow()
        return (
            <div className="list">
                {!this.state.interf.isLoading_market
                    ?
                    punks.slice(0, interf.punksShowingCount).map((punk, i) => {
                        return <div className="item-" key={i}>
                            <Link className="punk" onClick={() => this.__initDynamics()} to={`detail/Tpunks/${punk.idx}`}>
                                <div className="image">
                                    <J.Image className="--Punk_image" src={this.CT.utils.pis(punk.idx)} />
                                </div>
                                <div className="info">
                                    <div className="basic">
                                        <div className="name">
                                            <span>
                                                {punk.idx}
                                            </span>
                                        </div>
                                        <div className="type">
                                            <span>
                                                rank {punk.rank}
                                            </span>
                                            <span style={{ fontSize: '12px' }}>
                                                {punk.type}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    })
                    :
                    <C.Loading />}
            </div>
        )
    }
    r_history() {
        const { interf, storage } = this.state
        const activeBox = interf.history_activeBox || null
        let data = this.state.storage.recentlyEvents || null
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress');
        const _activateBox = (box) => {
            this._sst('interf', {
                history_activeBox: box
            })
        }
        return (
            <>
                <div className="history_boxes">
                    <div className="switcher">
                        <div className={`switch ${activeBox == 'LISTED' ? '-active' : ''}`} onClick={() => _activateBox('LISTED')}>
                            <i className="ri-list-unordered"></i>
                            <div className="title">
                                <span>
                                    recent listings
                                </span>
                            </div>
                        </div>
                        <div className={`switch ${activeBox == 'OFFER' ? '-active' : ''}`} onClick={() => _activateBox('OFFER')}>
                            <i className="ri-stack-line"></i>
                            <div className="title">
                                <span>
                                    recent bids
                                </span>
                            </div>
                        </div>
                        <div className={`switch ${activeBox == 'SOLD' ? '-active' : ''}`} onClick={() => _activateBox('SOLD')}>
                            <i className="ri-shopping-bag-3-line"></i>
                            <div className="title">
                                <span>
                                    sold
                                </span>
                            </div>
                        </div>
                    </div>
                    {data
                        ?
                        <>
                            {data.recentlyListed &&
                                activeBox == 'LISTED' &&
                                <div className="screen">
                                    <div className="content">
                                        {data.recentlyListed.map((item, i) => {
                                            return <Link className="item" key={i} onClick={() => this.__initDynamics()} to={`/detail/Tpunks/${item.punk_id}`}>
                                                <div className="image">
                                                    <J.Image width="60px" height="60px" className="--Punk_image" src={this.CT.utils.pis(item.punk_id)} />
                                                </div>
                                                <div className="info">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="key">
                                                                <span>
                                                                    #
                                                                </span>
                                                            </div>
                                                            <div className="value">
                                                                <span>
                                                                    {item.punk_id}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_usd-' + i}>
                                                                    <strong>$</strong>
                                                                    {this.CT.tooltip(null, 'eventlist_usd-' + i, <>$ {(item.priceInUsd).toLocaleString()}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.priceInUsd)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_price-' + i}>
                                                                    <J.Image width="20px" height="20px" src={getCurrencyData(item.currency_address).logo} />
                                                                    {this.CT.tooltip(null, 'eventlist_price-' + i, <>{(item.currency_amount / Math.pow(10, item.currency_decimals)).toLocaleString()}&nbsp;{getCurrencyData(item.currency_address).symbol}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.currency_amount / Math.pow(10, item.currency_decimals))}&nbsp;<strong style={{ opacity: '0.5' }}>{getCurrencyData(item.currency_address).symbol}</strong></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="extra">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value -custome" style={{ padding: '2px 6px', borderRadius: '6px', backgroundColor: 'rgba(0, 0, 0, 0.1)', color: '#FFF' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {new Date(Number(item.block_timestamp)).toLocaleDateString("en-US")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        })}
                                    </div>
                                </div>
                            }
                            {data.recentlyOffer &&
                                activeBox == 'OFFER' &&
                                <div className="screen">
                                    <div className="content">
                                        {data.recentlyOffer.map((item, i) => {
                                            return <Link className="item" key={i} onClick={() => this.__initDynamics()} to={`/detail/Tpunks/${item.punk_id}`}>
                                                <div className="image">
                                                    <J.Image width="60px" height="60px" className="--Punk_image" src={this.CT.utils.pis(item.punk_id)} />
                                                </div>
                                                <div className="info">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="key">
                                                                <span>
                                                                    #
                                                                </span>
                                                            </div>
                                                            <div className="value">
                                                                <span>
                                                                    {item.punk_id}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_usd-' + i}>
                                                                    <strong>$</strong>
                                                                    {this.CT.tooltip(null, 'eventlist_usd-' + i, <>$ {(item.priceInUsd).toLocaleString()}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.priceInUsd)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_price-' + i}>
                                                                    <J.Image width="20px" height="20px" src={getCurrencyData(item.currency_address).logo} />
                                                                    {this.CT.tooltip(null, 'eventlist_price-' + i, <>{(item.currency_amount / Math.pow(10, item.currency_decimals)).toLocaleString()}&nbsp;{getCurrencyData(item.currency_address).symbol}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.currency_amount / Math.pow(10, item.currency_decimals))}&nbsp;<strong style={{ opacity: '0.5' }}>{getCurrencyData(item.currency_address).symbol}</strong></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="extra">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value -custome" style={{ padding: '2px 6px', borderRadius: '6px', backgroundColor: 'rgba(0, 0, 0, 0.1)', color: '#FFF' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {new Date(Number(item.block_timestamp)).toLocaleDateString("en-US")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        })}
                                    </div>
                                </div>
                            }
                            {data.recentlySold &&
                                activeBox == 'SOLD' &&
                                <div className="screen">
                                    <div className="content">
                                        {data.recentlySold.map((item, i) => {
                                            return <Link className="item" key={i} onClick={() => this.__initDynamics()} to={`/detail/Tpunks/${item.punk_id}`}>
                                                <div className="image">
                                                    <J.Image width="60px" height="60px" className="--Punk_image" src={this.CT.utils.pis(item.punk_id)} />
                                                </div>
                                                <div className="info">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="key">
                                                                <span>
                                                                    #
                                                                </span>
                                                            </div>
                                                            <div className="value">
                                                                <span>
                                                                    {item.punk_id}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_usd-' + i}>
                                                                    <strong>$</strong>
                                                                    {this.CT.tooltip(null, 'eventlist_usd-' + i, <>$ {(item.priceInUsd).toLocaleString()}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.priceInUsd)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value">
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'eventlist_price-' + i}>
                                                                    <J.Image width="20px" height="20px" src={getCurrencyData(item.currency_address).logo} />
                                                                    {this.CT.tooltip(null, 'eventlist_price-' + i, <>{(item.currency_amount / Math.pow(10, item.currency_decimals)).toLocaleString()}&nbsp;{getCurrencyData(item.currency_address).symbol}</>)}
                                                                    <span>&nbsp;{this.CT.utils.normalizeNum(item.currency_amount / Math.pow(10, item.currency_decimals))}&nbsp;<strong style={{ opacity: '0.5' }}>{getCurrencyData(item.currency_address).symbol}</strong></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="extra">
                                                    <div className="part">
                                                        <div className="record">
                                                            <div className="value -custome" style={{ padding: '2px 6px', borderRadius: '6px', backgroundColor: 'rgba(0, 0, 0, 0.1)', color: '#FFF' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    {new Date(Number(item.block_timestamp)).toLocaleDateString("en-US")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        })}
                                    </div>
                                </div>
                            }
                        </>
                        :
                        'LOADING'
                    }
                </div>
            </>
        )
    }
    render() {
        const CT = this.CT
        const { interf, storage } = this.state
        return (
            <>
                <div className="market">
                    <div className="head">
                        <div className="title">
                            <span>
                                MARKET
                            </span>
                        </div>
                        {interf.activeTab != 'HISTORY' && storage.market &&
                            <div className="actions">
                                <J.Input placeHolder="punk id" iconName="ri-search-line" value={this.state.interf.searchId} onChange={(e) => this.search(e.target.value)} />
                                <J.Btn title="FILTER" type="simple" iconName="ri-equalizer-line" onClick={() => this.openFilter()} />
                            </div>
                        }
                    </div>
                    <div className="tabs">
                        <div className={`tab ${interf.activeTab == 'MARKET' ? '-active' : ''}`} onClick={() => this.setActiveTab('MARKET')}>
                            <span>market</span>
                        </div>
                        <div className={`tab ${interf.activeTab == 'ALL' ? '-active' : ''}`} onClick={() => this.setActiveTab('ALL')}>
                            <span>gallery</span>
                        </div>
                        <div className={`tab ${interf.activeTab == 'HISTORY' ? '-active' : ''}`} onClick={() => this.setActiveTab('HISTORY')}>
                            <span>history</span>
                        </div>
                    </div>
                    {interf.activeTab == 'MARKET' ? this.r_market() : (interf.activeTab == 'ALL' ? this.r_alltpunks() : this.r_history())}
                </div>
            </>
        )
    }
}