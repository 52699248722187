import React, { Component } from 'react'
import { Stepper, Step } from 'react-form-stepper';
import C from '~/components'
import J from 'juicy-ui'
import { data } from 'jquery';

export class Stepbox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
            loading: false,
            data: {}
        }
    }
    next(ref = null) {
        const last = this.props.steps.length - 1
        this.setState({
            step: this.state.refNextStep == 0 ? this.state.refNextStep : (this.state.step < last ? this.state.step + 1 : this.state.step),
            refPrevStep: ref
        })
    }
    prev(ref = null) {
        const first = 0
        this.setState({
            step: this.state.refPrevStep == 0 ? this.state.refPrevStep : (this.state.step > first ? this.state.step - 1 : this.state.step),
            refNextStep: ref
        })
    }
    render() {
        const { steps, submitter } = this.props
        return (
            <>
                <div className="Stepbox">
                    <Stepper activeStep={this.state.step} className={'CST_Stepper'} stepClassName={'CST_Stepper-step'} connectorStyleConfig={{
                        activeColor: '#fff'
                    }}>
                        {steps.map((step, i) => {
                            return <Step key={i} label={step.title} />
                        })}
                    </Stepper>
                    {steps.map((step, i) => {
                        return i == this.state.step && <>
                            <div key={i} className="content">
                                {step.content({ ...this.state.data, goNext: () => this.next(i - 1), goPrev: () => this.prev(this.state.step), change: (obj) => this.setState({ data: { ...this.state.data, ...obj } }) })}
                            </div>
                            <div className="actions">
                                {this.state.loading
                                    ?
                                    <div className="loading">
                                        <J.Btn title={<C.Loading inner />} />
                                    </div>
                                    :
                                    <>
                                        {this.state.step > 0 && <J.Btn iconName="ri-arrow-left-s-line" type="simple2" title="BACK" onClick={() => this.prev()} />}
                                        {this.props.haveOwnNextAction && this.props.haveOwnNextAction.includes(this.state.step)
                                            ?
                                            ''
                                            :
                                            <J.Btn iconName={i == steps.length - 1 ? submitter.iconName : "ri-arrow-right-s-line"} title={i == steps.length - 1 ? submitter.title : 'NEXT'} onClick={() => {
                                                this.setState({ loading: true })
                                                if (i == steps.length - 1) {
                                                    if (submitter.action) {
                                                        submitter.action(this.state.data)
                                                            .then(res => {
                                                                this.setState({ loading: false })
                                                            })
                                                            .catch((func) => {
                                                                func()
                                                                this.setState({ loading: false })
                                                            })
                                                    } else {
                                                        this.setState({ loading: false })
                                                    }
                                                } else {
                                                    if (step.action) {
                                                        step.action(this.state.data)
                                                            .then(res => {
                                                                this.setState({
                                                                    loading: false,
                                                                    data: res
                                                                })
                                                                this.next()
                                                            })
                                                            .catch((func) => {
                                                                func()
                                                                this.setState({ loading: false })
                                                            })
                                                    } else {
                                                        this.setState({ loading: false })
                                                    }
                                                }
                                            }} />
                                        }
                                    </>
                                }
                            </div>
                        </>
                    })}
                </div>
            </>
        )
    }
}