const ENV = {
    TRONSCAN: process.env.REACT_APP_TRONSCAN, // tronscan website
    TPUNKS_MARKET: process.env.REACT_APP_TPUNKS_MARKET, // tpunks market contract - new version
    TPUNKS_MARKET_OLD: process.env.REACT_APP_TPUNKS_MARKET_OLD, // tpunks market contract - version 1
    TPUNKS:  process.env.REACT_APP_TPUNKS, // tpunks contract
    API_BASE: process.env.REACT_APP_API_BASE, // API base route

    TELEGRAM_GROUP: process.env.REACT_APP_TELEGRAM_GROUP,
    TELEGRAM_CHANNEL_RECENTLY: process.env.REACT_APP_TELEGRAM_CHANNEL_RECENTLY,
    TELEGRAM_CHANNEL_FEED: process.env.REACT_APP_TELEGRAM_CHANNEL_FEED,
    TELEGRAM_GROUP: process.env.REACT_APP_TELEGRAM_GROUP,
    TWITTER: process.env.REACT_APP_TWITTER,
    TWITTER_BOT: process.env.REACT_APP_TWITTER_BOT,
}
export default ENV