import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import { Watch, Actions } from '~/scripts'
import C from '~/components';
import J from 'juicy-ui'

export default class Page extends Component {
    static contextType = BodyContext;
    constructor() {
        super()
        this.state = {
            interf: {},
            storage: {}
        }
    }
    componentWillMount() {
        const [CT] = this.context
        this.CT = CT
        CT.set_classing({ route: 'about' })
    }
    componentDidMount() {
        Watch.init(this)
    }

    render() {
        const { interf, storage } = this.state
        return (
            <>
                <div className="about">
                    <div className="title">
                        <span>
                            ABOUT
                        </span>
                    </div>
                    <div className="content">
                        <p>
                            TPUNKS project has started as a hobby NFT project of i <a style={{ display: 'contents' }} href="https://twitter.com/mondiblo" target="_blank">@mondiblo</a> and <a style={{ display: 'contents' }} href="https://twitter.com/bastardber" target="_blank">@bastardber</a> there were two main reasons for me to initiate this project:
                            <br />
                            <br />
                            - to practice Generative Adversarial Networks (GANs) with my own dataset,
                            <br />
                            <br />
                            - even though Cryptopunks were way cheaper last year than now, I had no money to buy any, so I challenged myself to make them "interesting enough" . So we came up with the tpunks concept. Tpunks carry the original Cryptopunk but they carry many different traits such as justin sun Tpunks and more attributes, And you know, sometimes children are much cooler than their parents. 😉
                        </p>
                    </div>
                </div>
            </>
        )
    }
}