import React, { Component } from 'react'
import J from 'juicy-ui'

export class Loading extends Component {
    render() {
        return (
            <div className={`LoadingBox ${this.props.inner ? '-inner' : ''}`}>
                <div className="loading">
                    <J.Image width="35px" height="35px" src={require(`~/assets/images/interface/loader.gif`).default} />
                </div>
            </div>
        )
    }
}