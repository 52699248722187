import tronWeb from 'tronweb';
import ENV from '~/context/_env'

class Contract {
    constructor() {
        this.contracts = {
            TPUNKS_MARKET: process.env.REACT_APP_TPUNKS_MARKET,
            TPUNKS: process.env.REACT_APP_TPUNKS,
            TPUNKS_MARKET_OLD: process.env.REACT_APP_TPUNKS_MARKET_OLD,
        }
        this.tronWebGlobal = new tronWeb({
            fullHost: 'https://api.shasta.trongrid.io',
            privateKey: '',
            solidityNode: 'https://api.trongrid.io',
        });
    }
    async apply(contract = 'TPUNKS_MARKET') {
        let bridge = null;
        bridge = window.tronWeb && window.tronWeb.contract().at(this.contracts[contract] || contract);
        return bridge
    }
    async Global() {
    }
    async Private() {
    }
}
export default Contract