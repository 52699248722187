import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { BodyContext } from '~/context/BodyContext.js'
import { Watch, Actions, Api, WalletManager } from '~/scripts'
import Select from 'react-select'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import C from '~/components';
import J from 'juicy-ui'

export default class PunkPage extends Component {
    static contextType = BodyContext;
    constructor(props) {
        super(props)
        this.state = {
            REFS: {
                page: 'punkpage'
            },
            interf: {
                id: props.match.params.id,
                isLoading: false,
                // 
                isBidding: false,
                isTransfering: false,
                isOffering: false,
                isOfferCanceling: false,
                isBuying: false,
                isWithdrawingOnOld: false,
                isWithdrawingOnNew: false,
                isApproving: false,
                // 
                iAm: 'NOTHING',
                // 
                activeTab_tables: 'HISTORY'
            },
            storage: {
            },
            form: {
            }
        }
    }
    componentWillMount() {
        const [CT] = this.context;
        this.CT = CT
        this.CT.set_classing({ route: 'market_punk' });
    }
    async componentDidMount() {
        Watch.init(this);
        await this._loadPunks()
        this.CT.utils.getCurrencies().then(res => this._sst('storage', { currencies: res }))
        await this._loadWallet()
        const page = this.props.location.query && this.props.location.query.sender || '/forSale'
        this.CT.set_dynamics({
            COME_FROM: page
        })
    }
    async _loadPunks() {
        setTimeout(() => {
            !this.state.storage.punk && this.CT.toast('error', 'Data not loaded, Refresh page.')
        }, 10000);
        this._sst('interf', { isLoading: true })
        const p1 = await this.getPunkData()
        if (p1) this._sst('interf', { isLoading: false })
    }
    async _loadWallet() {
        setTimeout(() => {
            if (!this.state.storage.wallet) {
                // this.CT.toast('error', 'wallet not detected. if TronLink installed on browser, refresh page')
                // window.location.reload();
            }
        }, 10000);
        try {
            setTimeout(async () => {
                const tronWeb = await this.CT.wallet.init()
                const selectedWallet = await this.CT.wallet.getAddress()
                const account = tronWeb && selectedWallet && tronWeb.trx.getAccount && await tronWeb.trx.getAccount(selectedWallet)
                account && this._sst('storage', {
                    wallet: {
                        balance: account.balance / 1000000,
                        address: selectedWallet
                    }
                })
            }, 200);
        }
        catch (err) { console.log(err) }
    }
    _sst(main, obj, callback) {
        if (!obj) {
            this.setState({
                [main]: {}
            }, () => callback ? callback() : null)
        } else {
            this.setState({
                [main]: {
                    ...this.state[main],
                    ...obj
                }
            }, () => callback ? callback() : null)
        }
    }
    getPunkData() {
        return new Promise(async (resolve, reject) => {
            const punk = await this.CT.storage.PUNKS[this.state.interf.id]
            let punkData = null
            setTimeout(async () => {
                try {
                    const tronWeb = await this.CT.wallet.init()
                    let selectedWallet = await this.CT.wallet.getAddress()
                    if (!selectedWallet) {
                        await setTimeout(async () => {
                            selectedWallet = window.tronWeb ? await window.tronWeb.defaultAddress.base58 : false
                        }, 200);
                    }
                    try {
                        punkData = await Api.get(`/punk?punk_id=${this.state.interf.id}`)
                    }
                    catch (err) {
                        this.CT.toast('error', 'server did not respond. try later')
                    }
                    punkData._bid = punkData.market_data.bids.length > 0 ? (punkData.market_data.bids[punkData.market_data.bids.length - 1].hasBid && punkData.market_data.bids[punkData.market_data.bids.length - 1]) : null
                    punkData._bid_highest = null
                    punkData._iHaveBidHere = null
                    punkData._myBids = null
                    if (punkData.market_data.bids.length > 0) {
                        let highestBid = { priceInUsd: 0 }
                        let iHaveBidHere = false
                        let myBids = {
                            old: null,
                            new: null
                        }
                        punkData.market_data.bids.map(bid => {
                            highestBid = bid.priceInUsd >= highestBid.priceInUsd ? bid : highestBid
                            if (bid.bidder == selectedWallet) {
                                iHaveBidHere = true
                                bid.on_old_market ? myBids.old = bid : myBids.new = bid
                            }
                        })
                        punkData._bid_highest = highestBid
                        punkData._iHaveBidHere = iHaveBidHere
                        punkData._myBids = myBids
                    }
                    punkData.owner = punkData.is_old_market ? punkData.market_data.list.seller : punkData.owner
                    selectedWallet ? this._sst('interf', { iAm: punkData.owner == selectedWallet ? 'OWNER' : 'USER' }) : this._sst('interf', { iAm: 'GUEST' })
                    this._sst('storage', {
                        punk: {
                            ...punk,
                            owner: punkData.owner || null,
                            data: punkData
                        }
                    })
                    resolve(true)
                }
                catch (err) {
                    const tronWeb = await this.CT.wallet.init()
                    let selectedWallet = await this.CT.wallet.getAddress()
                    if (!selectedWallet) {
                        await setTimeout(async () => {
                            selectedWallet = window.tronWeb ? await window.tronWeb.defaultAddress.base58 : false
                        }, 200);
                    }
                    this.CT.toast('error', 'server error: punk not loaded')
                    selectedWallet ? this._sst('interf', { iAm: 'USER' }) : this._sst('interf', { iAm: 'GUEST' })
                    this._sst('storage', {
                        punk: {
                            ...punk,
                            ...this.CT.storage.PUNKS[this.state.interf.id]
                        }
                    })
                    resolve(true)
                }
            }, 500);
        })
    }
    async bridge_makeBid() {
        const currencies = this.state.storage.currencies
        const options = []
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        this.state.storage.currencies.map((currency, i) => {
            options.push({
                label: <div className="CurrencyOption">
                    <J.Image width="20px" height="20px" src={currency.logo} />
                    {currency.name}
                </div>,
                value: currency.contractAddress,
                index: i
            })
            currency.symbol.toUpperCase() == 'TRX' && this._sst('form', { currencyAddress: currency.contractAddress, selectedCurrencyIndex: i || 0 })
        })
        // 
        const newMarket = () => {
            this.CT.popup.open(null, {
                title: 'ENTER BID',
                modify: {
                    showConfirmButton: false,
                },
                body: <>
                    <C.Stepbox
                        haveOwnNextAction={[1]}
                        steps={[
                            {
                                title: 'price',
                                content: data => <>
                                    {this.state.storage.punk.data._iHaveBidHere && <div className="popup--form-item" style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <i className="ri-error-warning-line" style={{ color: '#ddcd39' }}></i>&nbsp;
                                        <div className="popup--form-title" style={{ marginBottom: 0 }} onClick={() => this.CT.tooltip(this.fooRef)}>
                                            <p>
                                                you have already made a bid, this will cancel your bid and will replace it with a new one.
                                            </p>
                                        </div>
                                    </div>}
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                enter price
                                            </span>
                                        </div>
                                        <J.Input placeHolder='0' defaultValue={parseFloat(this.state.form.bid_price) || ''} iconName="ri-money-dollar-circle-line" onChange={(e) => this._sst('form', { bid_price: e.target.value })} />
                                    </div>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                currency
                                            </span>
                                        </div>
                                        <Select defaultValue={options[this.state.form.selectedCurrencyIndex]} options={options} onChange={(e) => this._sst('form', { currencyAddress: e.value, selectedCurrencyIndex: e.index })} styles={{
                                            container: styles => ({
                                                ...styles,
                                                width: '100%',
                                                zIndex: 1000
                                            }),
                                            control: styles => ({
                                                ...styles,
                                                width: '100%',
                                                flexDirection: 'row',
                                                zIndex: 1000
                                            }),
                                            valueContainer: styles => ({
                                                ...styles,
                                                flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                            }),
                                            indicatorSeparator: styles => ({
                                                ...styles,
                                                display: 'none'
                                            }),
                                        }} />
                                    </div>
                                </>,
                                action: () => {
                                    return new Promise(async (resolve, reject) => {
                                        if (!isNaN(this.state.form.bid_price)) {
                                            if (this.state.form.bid_price >= this.CT.statics.limits.minBidValueOn(this.state.form.currencyAddress)) {
                                                const currencySymbol = getCurrencyData(this.state.form.currencyAddress).symbol
                                                // const lastBid = this.state.storage.punk.data.market_data.bids[this.state.storage.punk.data.market_data.bids.length - 1] || null
                                                // if (lastBid) {
                                                //     const lastBidCurrencySymbol = getCurrencyData(lastBid.currencyAddress).symbol
                                                //     const lastBidCurrencyToUSDT = this.state.storage.punk.data.market_data.bid_in_usd || 0
                                                //     if (lastBid.hasBid && this.state.form.bid_price <= lastBidCurrencyToUSDT) reject(() => this.CT.toast('error', `bid price must be more than current price: ${lastBid.value} ${lastBidCurrencySymbol} = ${lastBidCurrencyToUSDT}USDT`))
                                                //     else {
                                                //         if (currencySymbol != 'TRX') {
                                                //             const curContract = await this.CT.contract.apply(this.state.form.currencyAddress)
                                                //             const allowance = (await curContract.allowance(this.state.storage.wallet.address, this.CT.statics.contract.address.TPUNKS_MARKET).call()).toString()
                                                //             const proc_allowance = allowance / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)
                                                //             const balance = (await curContract.balanceOf(this.state.storage.wallet.address).call())._hex
                                                //             const proc_balance = parseInt(balance, 16) / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)
                                                //             if (proc_balance >= parseFloat(this.state.form.bid_price)) resolve({ id: this.state.interf.id, bid_price: parseFloat(this.state.form.bid_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: currencySymbol, allowance: proc_allowance, fee: getCurrencyData(this.state.form.currencyAddress).marketFee })
                                                //             else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                                //         } else {
                                                //             if (this.state.storage.wallet.balance >= parseFloat(this.state.form.bid_price)) resolve({ id: this.state.interf.id, bid_price: parseFloat(this.state.form.bid_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: currencySymbol, allowance: 1000000000000000000000000, fee: getCurrencyData(this.state.form.currencyAddress).marketFee })
                                                //             else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                                //         }
                                                //     }
                                                // }
                                                // else {
                                                if (currencySymbol != 'TRX') {
                                                    const curContract = await this.CT.contract.apply(this.state.form.currencyAddress)
                                                    const allowance = (await curContract.allowance(this.state.storage.wallet.address, this.CT.statics.contract.address.TPUNKS_MARKET).call()).toString()
                                                    const proc_allowance = allowance / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)
                                                    const balance = (await curContract.balanceOf(this.state.storage.wallet.address).call())._hex
                                                    const proc_balance = parseInt(balance, 16) / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)
                                                    if (proc_balance >= parseFloat(this.state.form.bid_price)) resolve({ id: this.state.interf.id, bid_price: parseFloat(this.state.form.bid_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: currencySymbol, allowance: proc_allowance, fee: getCurrencyData(this.state.form.currencyAddress).marketFee, })
                                                    else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                                } else {
                                                    if (this.state.storage.wallet.balance >= parseFloat(this.state.form.bid_price)) resolve({ id: this.state.interf.id, bid_price: parseFloat(this.state.form.bid_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: currencySymbol, allowance: 1000000000000000000000000, fee: getCurrencyData(this.state.form.currencyAddress).marketFee, })
                                                    else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                                }
                                                // }
                                            } else {
                                                reject(() => this.CT.toast('error', 'bid price must be more than ' + this.CT.utils.normalizeNum(this.CT.statics.limits.minBidValueOn(this.state.form.currencyAddress))))
                                            }
                                        } else {
                                            reject(() => this.CT.toast('error', 'bid price must be number '))
                                        }
                                    })
                                }
                            },
                            {
                                title: 'Unlock currency',
                                content: data => <>
                                    approve your bid of {data.bid_price + ' ' + data.currencySymbol} on market:
                                    <br />
                                    <br />
                                    {this.state.interf.isApproving ? <C.Loading /> : (data.allowance >= data.bid_price ? data.goNext() : <><J.Btn title="APPROVE" iconName="ri-check-fill" onClick={async () => this.__approveCurrency(data.currencyAddress, (data.bid_price * Math.pow(10, getCurrencyData(data.currencyAddress).decimals))).then(res => res && data.change({ allowance: data.bid_price }))} /></>)}
                                </>,
                                action: data => {
                                    return new Promise((resolve, reject) => {
                                        data.allowance >= data.bid_price ? resolve(data) : reject(() => this.CT.toast('error', 'first approve your currency on market'))
                                    })
                                },
                            },
                            {
                                title: 'confirm',
                                content: data => <>
                                    <div className="popup--signbox">
                                        <span className="popup--signbox-head">TRANSACTION</span>
                                        <p>event: <strong>bid</strong></p>
                                        <p>punk: <strong> #{data.id}</strong></p>
                                        <p>price: <strong>{data.bid_price}</strong>&nbsp;{data.currencySymbol}</p>
                                        {/* <p>market fee: <strong>{data.fee}</strong>%</p> */}
                                        <p>do you accept?</p>
                                    </div>
                                </>
                            },
                        ]}
                        submitter={{
                            title: 'CONFIRM BID',
                            iconName: 'ri-check-line',
                            action: data => {
                                return new Promise(async (resolve, reject) => {
                                    this._sst('form', null)
                                    try {
                                        const contract = await this.CT.contract.apply('TPUNKS_MARKET')
                                        const decimals = getCurrencyData(data.currencyAddress).decimals
                                        const proc_price = this.CT.utils.BigToNum((data.bid_price * Math.pow(10, decimals)).toString())
                                        const TXN = await contract.enterBidForToken(data.id, proc_price, data.currencyAddress).send({
                                            feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress),
                                            callValue: data.currencySymbol == 'TRX' ? proc_price : 0
                                        })
                                        if (TXN) {
                                            this.CT.toast('success', <div>Your bid was submitted successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                                            this.CT.popup.confirm()
                                            resolve(true)
                                        } else {
                                            this.CT.toast('error', 'bid not created')
                                            this.CT.popup.cancel()
                                            reject()
                                        }
                                    }
                                    catch (err) {
                                        console.log(err)
                                        this.CT.toast('error', 'bid not created')
                                        this.CT.popup.cancel()
                                    }
                                })
                            }
                        }}
                    />
                </>
            })
                .then(async res => console.log('DONE'))
                .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
        }
        const oldMarket = () => {
            this.CT.popup.open(null, {
                title: 'ENTER BID',
                modify: {
                    showConfirmButton: false,
                },
                body: <>
                    <C.Stepbox
                        steps={[
                            {
                                title: 'price',
                                content: data => <>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                enter price ({getCurrencyData(this.state.form.currencyAddress).symbol})
                                            </span>
                                        </div>
                                        <J.Input placeHolder='0' defaultValue={parseFloat(this.state.form.bid_price) || ''} iconName="ri-money-dollar-circle-line" onChange={(e) => this._sst('form', { bid_price: e.target.value })} />
                                    </div>
                                </>,
                                action: () => {
                                    return new Promise(async (resolve, reject) => {
                                        if (!isNaN(this.state.form.bid_price)) {
                                            if (this.state.storage.punk.data._bid && this.state.form.bid_price > (this.state.storage.punk.data._bid.value / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)) || !this.state.storage.punk.data._bid) {
                                                if (this.state.form.bid_price >= this.CT.statics.limits.minBidValueOn(this.state.form.currencyAddress)) {
                                                    if (this.state.storage.wallet.balance >= parseFloat(this.state.form.bid_price)) resolve({ id: this.state.interf.id, bid_price: parseFloat(this.state.form.bid_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: getCurrencyData(this.state.form.currencyAddress).symbol, allowance: 1000000000000000000000000, fee: getCurrencyData(this.state.form.currencyAddress).marketFee })
                                                    else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                                } else {
                                                    reject(() => this.CT.toast('error', 'bid price must be more than ' + this.CT.utils.normalizeNum(this.CT.statics.limits.minBidValueOn(this.state.form.currencyAddress))))
                                                }
                                            } else {
                                                reject(() => this.CT.toast('error', 'bid price must be more than current price: ' + this.state.storage.punk.data._bid.value / Math.pow(10, getCurrencyData(this.state.form.currencyAddress).decimals)))
                                            }
                                        } else {
                                            reject(() => this.CT.toast('error', 'bid price must be number '))
                                        }
                                    })
                                }
                            },
                            {
                                title: 'confirm',
                                content: data => <>
                                    <div className="popup--signbox">
                                        <span className="popup--signbox-head">TRANSACTION</span>
                                        <p>event: <strong>bid</strong></p>
                                        <p>punk: <strong> #{data.id}</strong></p>
                                        <p>price: <strong>{data.bid_price}</strong>&nbsp;{data.currencySymbol}</p>
                                        {/* <p>market fee: <strong>{data.fee}</strong>%</p> */}
                                        <p>do you accept?</p>
                                    </div>
                                </>
                            },
                        ]}
                        submitter={{
                            title: 'CONFIRM BID',
                            iconName: 'ri-check-line',
                            action: data => {
                                return new Promise(async (resolve, reject) => {
                                    this._sst('form', null)
                                    try {
                                        const contract = await this.CT.contract.apply('TPUNKS_MARKET_OLD')
                                        const decimals = getCurrencyData(data.currencyAddress).decimals
                                        const proc_price = this.CT.utils.BigToNum((data.bid_price * Math.pow(10, decimals)).toString());
                                        const TXN = await contract.enterBidForPunk(data.id).send({
                                            feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress),
                                            callValue: proc_price
                                        })
                                        if (TXN) {
                                            this.CT.toast('success', <div>Your bid was submitted successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                                            this.CT.popup.confirm()
                                            resolve(true)
                                        } else {
                                            this.CT.toast('error', 'bid not created')
                                            this.CT.popup.cancel()
                                            reject()
                                        }
                                    }
                                    catch (err) {
                                        console.log(err)
                                        this.CT.toast('error', 'bid not created')
                                        this.CT.popup.cancel()
                                    }
                                })
                            }
                        }}
                    />
                </>
            })
                .then(async res => console.log('DONE'))
                .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
        }
        const warning = () => {
            this.CT.popup.open('warning', {
                title: ' ',
                modify: {
                },
                body: <>
                    <p style={{ textAlign: 'left' }}>
                        you currently have a bid on old market it's not possible to edit that, so first cancel it then enter a new bid.
                    </p>
                </>
            })
        }
        // 
        if (this.state.storage.punk.data._myBids && this.state.storage.punk.data._myBids.old) {
            warning()
        } else {
            this.state.storage.punk.data.is_old_market ? newMarket() : newMarket();
        }
        // 
    }
    async __approveCurrency(currency, value) {
        const proc_value = this.CT.utils.BigToNum((value).toString())
        this._sst('interf', { isApproving: true })
        try {
            const contract = await this.CT.contract.apply(currency)
            const approvedStatus = await contract.approve(this.CT.statics.contract.address.TPUNKS_MARKET, proc_value).send({
                feeLimit: this.CT.statics.contract.feeLimit,
            });
            this._sst('interf', { isApproving: false })
            return approvedStatus && true
        }
        catch (err) {
            this._sst('interf', { isApproving: false })
            this.CT.toast('error', 'action not completed')
        }
    }
    async bridge_makeOffer() {
        const currencies = this.state.storage.currencies
        const options = []
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        this.state.storage.currencies.map((currency, i) => {
            options.push({
                label: <div className="CurrencyOption">
                    <J.Image width="20px" height="20px" src={currency.logo} />
                    {currency.name}
                </div>,
                value: currency.contractAddress,
                index: i
            })
            currency.symbol.toUpperCase() == 'TRX' && this._sst('form', { currencyAddress: currency.contractAddress, selectedCurrencyIndex: i || 0 })
        })
        // 
        const newMarket = () => {
            this.CT.popup.open(null, {
                title: 'LIST YOUR PUNK FOR SALE',
                modify: {
                    showConfirmButton: false,
                },
                body: <>
                    <C.Stepbox
                        haveOwnNextAction={[1]}
                        steps={[
                            {
                                title: 'price',
                                content: data => <>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                enter price
                                            </span>
                                        </div>
                                        <J.Input placeHolder='0' defaultValue={parseFloat(this.state.form.offer_price) || ''} iconName="ri-money-dollar-circle-line" onChange={(e) => this._sst('form', { offer_price: e.target.value })} />
                                    </div>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                currency
                                            </span>
                                        </div>
                                        <Select defaultValue={options[this.state.form.selectedCurrencyIndex]} options={options} onChange={(e) => this._sst('form', { currencyAddress: e.value, selectedCurrencyIndex: e.index })} styles={{
                                            container: styles => ({
                                                ...styles,
                                                width: '100%',
                                                zIndex: 1000
                                            }),
                                            control: styles => ({
                                                ...styles,
                                                width: '100%',
                                                flexDirection: 'row',
                                                zIndex: 1000
                                            }),
                                            valueContainer: styles => ({
                                                ...styles,
                                                flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                            }),
                                            indicatorSeparator: styles => ({
                                                ...styles,
                                                display: 'none'
                                            }),
                                        }} />
                                    </div>
                                </>,
                                action: () => {
                                    return new Promise(async (resolve, reject) => {
                                        if (!isNaN(this.state.form.offer_price)) {
                                            if (this.state.form.offer_price >= this.CT.statics.limits.minOfferValueOn(this.state.form.currencyAddress)) {
                                                const selectedWallet = await this.CT.wallet.getAddress()
                                                const contract = await this.CT.contract.apply('TPUNKS')
                                                const currencySymbol = getCurrencyData(this.state.form.currencyAddress).symbol
                                                const isApprovedForAll = await contract.isApprovedForAll(selectedWallet, this.CT.contract.contracts['TPUNKS_MARKET']).call();
                                                resolve({ id: this.state.interf.id, offer_price: parseFloat(this.state.form.offer_price), currencyAddress: this.state.form.currencyAddress, currencySymbol: currencySymbol, isApprovedForAll: isApprovedForAll, fee: getCurrencyData(this.state.form.currencyAddress).marketFee })
                                            } else {
                                                reject(() => this.CT.toast('error', 'bid price must be more than ' + this.CT.utils.normalizeNum(this.CT.statics.limits.minOfferValueOn(this.state.form.currencyAddress))))
                                            }
                                        } else {
                                            reject(() => this.CT.toast('error', 'bid price must be number'))
                                        }
                                    })
                                }
                            },
                            {
                                title: 'unlock trading',
                                content: data => <>
                                    approve listing on tpunks marketplace:
                                    <br />
                                    <br />
                                    {this.state.interf.isApproving ? <C.Loading /> : (data.isApprovedForAll ? data.goNext() : <><J.Btn title="APPROVE" onClick={async () => this.__approveForAllTokens().then(res => res && data.change({ isApprovedForAll: true }))} /></>)}
                                </>,
                                action: data => {
                                    return new Promise((resolve, reject) => {
                                        data.isApprovedForAll ? resolve(data) : reject(() => this.CT.toast('error', 'first approve your punks on market'))
                                    })
                                },
                            },
                            {
                                title: 'confirm',
                                content: data => <>
                                    <div className="popup--signbox">
                                        <span className="popup--signbox-head">TRANSACTION</span>
                                        <p>event: <strong>listing</strong></p>
                                        <p>punk: <strong> #{data.id}</strong></p>
                                        <p>price: <strong>{data.offer_price}</strong>&nbsp;{data.currencySymbol}</p>
                                        <p>market fee: <strong>{data.fee}</strong>%</p>
                                        <p style={{ opacity: 0.5 }}>Listing is free! At the time of the sale, the fee will be deducted.</p>
                                        <p>do you accept? </p>
                                    </div>
                                </>
                            },
                        ]}
                        submitter={{
                            title: 'LIST PUNK',
                            iconName: 'ri-check-line',
                            action: data => {
                                return new Promise(async (resolve, reject) => {
                                    this._sst('form', null)
                                    try {
                                        const contract = await this.CT.contract.apply('TPUNKS_MARKET')
                                        const decimals = this.CT.utils.findInBy(data.currencyAddress, currencies, 'contractAddress').decimals
                                        const proc_price = this.CT.utils.BigToNum((data.offer_price * Math.pow(10, decimals)).toString());
                                        const TXN = await contract.listTokenForSale(this.state.interf.id, proc_price, data.currencyAddress).send({
                                            feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress),
                                        });
                                        if (TXN) {
                                            this.CT.toast('success', <div>Your item is now listed for sale.<br />Wait for 15 seconds, then refresh the page.</div>)
                                            this.CT.popup.confirm()
                                            resolve(true)
                                        } else {
                                            this.CT.toast('error', 'punk not listed')
                                            this.CT.popup.cancel()
                                            reject()
                                        }
                                    }
                                    catch (err) {
                                        console.log(err)
                                        this.CT.toast('error', 'punk not listed')
                                        this.CT.popup.cancel()
                                    }
                                })
                            }
                        }}
                    />
                </>
            })
                .then(async res => console.log('DONE'))
                .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
        }
        const oldMarket = () => { }
        // 
        this.state.storage.punk.data.is_old_market ? newMarket() : newMarket();
    }
    async __approveForAllTokens(market = 'TPUNKS_MARKET') {
        this._sst('interf', { isApproving: true })
        try {
            const contract = await this.CT.contract.apply('TPUNKS')
            const approvedStatus = await contract.setApprovalForAll(this.CT.contract.contracts[market], true).send({
                feeLimit: this.CT.statics.contract.feeLimit,
            });
            this._sst('interf', { isApproving: false })
            return approvedStatus && true
        }
        catch (err) {
            this.CT.toast('error', 'action not completed')
            this._sst('interf', { isApproving: false })
        }
    }
    async bridge_cancelOffer() {
        try {
            const act = await this.CT.popup.open(null, {
                title: 'DELIST TPUNK',
                modify: {
                    showDenyButton: true,
                    confirmButtonText: 'YES',
                    denyButtonText: 'NO'
                },
                body: <>
                    <p style={{ textAlign: 'left' }}>
                        are you sure you want to remove your Tpunk from marketplace?
                    </p>
                </>
            })
            if (act) {
                this._sst('interf', { isOfferCanceling: true })
                try {
                    const contract = this.state.storage.punk.data.is_old_market ? await this.CT.contract.apply('TPUNKS_MARKET_OLD') : await this.CT.contract.apply('TPUNKS_MARKET')
                    const TXN = this.state.storage.punk.data.is_old_market ? await contract.punkNoLongerForSale(this.state.interf.id).send({ feeLimit: this.CT.statics.contract.feeLimit }) : await contract.unListTokenForSale(this.state.interf.id).send({ feeLimit: this.CT.statics.contract.feeLimit });
                    if (TXN) {
                        this._sst('interf', { isOfferCanceling: false })
                        this.state.storage.punk.data.is_old_market ? this.CT.toast('success', <div>punk delisted successfully.<br />Wait a minute until your transaction confirms on the blockchain, then refresh the page.</div>) : this.CT.toast('success', <div>Tpunk delisted successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                        return true
                    } else {
                        this._sst('interf', { isOfferCanceling: false })
                        this.CT.toast('error', 'action not completed')
                        return false
                    }
                }
                catch (err) {
                    this._sst('interf', { isOfferCanceling: false })
                    this.CT.toast('error', 'action rejected')
                    return false
                }
            }
        }
        catch (err) {
            this.CT.toast('info', err == 'DENY' && 'action canceled')
            return false
        }
    }
    async bridge_sellToBid(data) {
        const bid = data || this.state.storage.punk.data.market_data.bids[this.state.storage.punk.data.market_data.bids.length - 1]
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        const isOldMarket = this.state.storage.punk.data.is_old_market
        const onOldMarket = bid.on_old_market
        const currencyData = getCurrencyData(bid.currencyAddress)
        if (isOldMarket && !onOldMarket)
            this.CT.popup.open('warning', {
                title: 'DELIST NEEDED',
                modify: {
                    showConfirmButton: true,
                    // confirmButtonText: 'DELIST NOW',
                    // showDenyButton: true,
                    // denyButtonText: 'CANCEL'
                },
                body: <>
                    <p style={{ textAlign: 'left' }}>
                        the bid you're accepting is on the new market but your Tpunk is listed on the old market.
                        <br />
                        so to accept, first delist your Tpunk.
                    </p>
                </>
            })
                .then(async res => {
                    // if (isApproved) {
                    //     const delisted = await LOCAL_DELIST()
                    //     // delisted && this.CT.toast('success', 'now you can accept bid for sale. wait a minute for blockchain confirmation, and refresh page')
                    //     // const acted = delisted && await ACTION(onOldMarket)
                    //     delisted ? resolve(true) : reject(() => this.CT.toast('error', 'action rejected'))
                    // } else {
                    //     const delisted = await LOCAL_DELIST()
                    //     if (delisted) {
                    //         this.CT.toast('warning', 'first approve your punk on market')
                    //         const approved = await this.__approveForAllTokens()
                    //         // approved && this.CT.toast('success', 'now you can accept bid for sale. wait a minute for blockchain confirmation, and refresh page')
                    //         // const acted = approved && await ACTION(onOldMarket)
                    //         approved ? resolve(true) : reject(() => this.CT.toast('error', 'action rejected'))
                    //     } else {
                    //         reject(() => this.CT.toast('error', 'delist not completed'))
                    //     }
                    // }
                })
        else
            this.CT.popup.open(null, {
                title: 'ACCEPT BID',
                modify: {
                    showConfirmButton: false,
                },
                body: <>
                    <C.Stepbox
                        steps={[
                            {
                                title: 'info',
                                content: data => <>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                info
                                            </span>
                                        </div>
                                        <div className="image" style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <J.Image width="70px" height="70px" className="--Punk_image" src={this.CT.utils.pis(this.state.interf.id)} />&nbsp;<span style={{ fontSize: '40px' }}>#{this.state.interf.id}</span>
                                        </div>
                                    </div>
                                    {onOldMarket
                                        ?
                                        <div className="popup--form-item">
                                            <div className="popup--form-title">
                                                <span>
                                                    price
                                                </span>
                                            </div>
                                            <div className="--Price">
                                                <span className="number">
                                                    {bid.value / Math.pow(10, currencyData.decimals) || '-'}
                                                </span>
                                                <span className="sign">
                                                    {currencyData.symbol}
                                                </span>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                        :
                                        <div className="popup--form-item">
                                            <div className="popup--form-title">
                                                <span>
                                                    price
                                                </span>
                                            </div>
                                            <div className="--Price">
                                                <span className="number">
                                                    {(bid.value / Math.pow(10, currencyData.decimals)).toFixed(4) || '-'}
                                                </span>
                                                <span className="sign">
                                                    {currencyData.symbol}
                                                </span>
                                                <br />
                                                <br />
                                            </div>
                                            <div className="--Price">
                                                <span className="number">
                                                    - {currencyData.marketFee || '-'}
                                                </span>
                                                <span className="sign">
                                                    % (market fee)
                                                </span>
                                                <br />
                                                <br />
                                            </div>
                                            <div className="--Price">
                                                <span className="number">
                                                    {/* {(bid.value / Math.pow(10, currencyData.decimals)) || '-'} */}
                                                    = {((bid.value / Math.pow(10, currencyData.decimals)) - ((bid.value / Math.pow(10, currencyData.decimals)) * currencyData.marketFee / 100)).toFixed(4)}
                                                </span>
                                                <span className="sign">
                                                    {currencyData.symbol}
                                                </span>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    }
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                bidder
                                            </span>
                                        </div>
                                        {this.CT.utils.walletAddress(bid.bidder)}
                                    </div>
                                    <div className="popup--form-item">
                                        <div className="popup--form-title">
                                            <span>
                                                currency
                                            </span>
                                        </div>
                                        <div className="--Blockchain">
                                            <J.Image width="25px" height="25px" src={currencyData.logo} />
                                            <span>
                                                {currencyData.name} - {currencyData.symbol}
                                            </span>
                                        </div>
                                    </div>
                                </>,
                                action: () => {
                                    return new Promise(async (resolve, reject) => {
                                        resolve({ id: this.state.interf.id, bidder: bid.bidder, price: bid.value / Math.pow(10, currencyData.decimals), symbol: currencyData.symbol, currencyAddress: bid.currencyAddress, fee: currencyData.marketFee })
                                    })
                                }
                            },
                            {
                                title: 'confirm',
                                content: data => <>
                                    <div className="popup--signbox">
                                        <span className="popup--signbox-head">TRANSACTION</span>
                                        <p>event: <strong>accept bid</strong></p>
                                        <p>punk: <strong> #{data.id}</strong></p>
                                        {onOldMarket
                                            ?
                                            <p>price: <strong>{(data.price).toFixed(4)}</strong></p>
                                            :
                                            <p>price: <strong>{(data.price).toFixed(4)}&nbsp;-&nbsp;{data.fee}%&nbsp;=&nbsp;{((bid.value / Math.pow(10, currencyData.decimals)) - ((bid.value / Math.pow(10, currencyData.decimals)) * currencyData.marketFee / 100)).toFixed(4)}</strong>&nbsp;{data.symbol}</p>
                                        }
                                        <p>bidder: <strong>{this.CT.utils.walletAddress(data.bidder)}</strong></p>
                                        {!onOldMarket &&
                                            <p>market fee: <strong>{data.fee}</strong>%</p>
                                        }
                                        <p>do you accept? </p>
                                    </div>
                                </>
                            },
                        ]}
                        submitter={{
                            title: 'ACCEPT',
                            iconName: 'ri-check-line',
                            action: data => {
                                return new Promise(async (resolve, reject) => {
                                    try {
                                        const proc_price = this.CT.utils.BigToNum((data.price * Math.pow(10, currencyData.decimals)).toString())
                                        const tpunksContract = await this.CT.contract.apply('TPUNKS')
                                        const selectedWallet = await this.CT.wallet.getAddress()
                                        // const isApproved = await tpunksContract.isApprovedForAll(selectedWallet, isOldMarket ? this.CT.contract.contracts['TPUNKS_MARKET_OLD'] : this.CT.contract.contracts['TPUNKS_MARKET']).call();
                                        const isApproved = await tpunksContract.isApprovedForAll(selectedWallet, this.CT.contract.contracts['TPUNKS_MARKET']).call();
                                        // const contract = isOldMarket ? await this.CT.contract.apply('TPUNKS_MARKET_OLD') : await this.CT.contract.apply('TPUNKS_MARKET')
                                        const contract = await this.CT.contract.apply('TPUNKS_MARKET')
                                        const contractOld = await this.CT.contract.apply('TPUNKS_MARKET_OLD')
                                        const ACTION = async (isOld) => {
                                            try {
                                                const TXN = isOld ? await contractOld.acceptBidForPunk(data.id, proc_price).send({ feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress) }) : await contract.acceptBidForToken(data.id, data.bidder, proc_price).send({ feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress) })
                                                if (TXN) {
                                                    isOld ? this.CT.toast('success', <div>Tpunk sold successfully.<br />Wait a minute until your transaction confirms on the blockchain, then refresh the page.</div>) : this.CT.toast('success', <div>Tpunk sold successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                                                    this.CT.popup.confirm()
                                                    return true
                                                } else {
                                                    this.CT.toast('error', 'action not completed')
                                                    this.CT.popup.deny()
                                                    return false
                                                }
                                            }
                                            catch (err) {
                                                return false
                                            }
                                        }
                                        const LOCAL_DELIST = async () => {
                                            try {
                                                const canceled = await this.bridge_cancelOffer()
                                                if (canceled) return true
                                                else return false
                                            }
                                            catch (err) {
                                                return false
                                            }
                                        }
                                        if (isApproved) {
                                            const acted = await ACTION(onOldMarket)
                                            acted ? resolve(true) : reject(() => this.CT.toast('error', 'action rejected'))
                                        } else {
                                            this.CT.toast('warning', 'first approve your punk on market')
                                            const approved = await this.__approveForAllTokens()
                                            const acted = approved && await ACTION(onOldMarket)
                                            acted ? resolve(true) : reject(() => this.CT.toast('error', 'action rejected'))
                                        }
                                        this.CT.popup.cancel()
                                    }
                                    catch (err) {
                                        this.CT.toast('error', 'action rejected')
                                        this.CT.popup.deny()
                                        reject(() => this.CT.toast('error', 'action rejected'))
                                    }
                                })
                            }
                        }}
                    />
                </>
            })
                .then(async res => console.log('DONE'))
                .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
    }
    async bridge_withdrawBid(isOldMarket) {
        this.CT.popup.open(null, {
            title: 'CANCEL BID',
            modify: {
                showDenyButton: true,
                confirmButtonText: 'YES',
                denyButtonText: 'NO'
            },
            body: <>
                <p style={{ textAlign: 'left' }}>
                    are you sure you want to cancel your bid?
                </p>
            </>
        })
            .then(async res => {
                this._sst('interf', isOldMarket ? { isWithdrawingOnOld: true } : { isWithdrawingOnNew: true })
                try {
                    const contract = isOldMarket ? await this.CT.contract.apply('TPUNKS_MARKET_OLD') : await this.CT.contract.apply('TPUNKS_MARKET')
                    const TXN = isOldMarket ? await contract.withdrawBidForPunk(this.state.interf.id).send({ feeLimit: this.CT.statics.contract.feeLimit }) : await contract.cancelBidForToken(this.state.interf.id).send({ feeLimit: this.CT.statics.contract.feeLimit })
                    if (TXN) {
                        this._sst('interf', isOldMarket ? { isWithdrawingOnOld: false } : { isWithdrawingOnNew: true })
                        isOldMarket ? this.CT.toast('success', <div>Bid canceled successfully.<br />Wait a minute until your transaction confirms on the blockchain, then refresh the page.</div>) : this.CT.toast('success', <div>Bid canceled successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                    } else {
                        this._sst('interf', isOldMarket ? { isWithdrawingOnOld: true } : { isWithdrawingOnNew: false })
                        this.CT.toast('error', 'action not completed')
                    }
                }
                catch (err) {
                    this._sst('interf', isOldMarket ? { isWithdrawingOnOld: false } : { isWithdrawingOnNew: false })
                    this.CT.toast('error', 'action rejected')
                }
            })
            .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
    }
    async bridge_buybyOffer() {
        const data = this.state.storage.punk.data.market_data.list
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        const currencyData = getCurrencyData(data.currencyAddress)
        const isOldMarket = this.state.storage.punk.data.is_old_market
        this.CT.popup.open(null, {
            title: 'BUY THE PUNK',
            modify: {
                showConfirmButton: false,
            },
            body: <>
                <C.Stepbox
                    haveOwnNextAction={[1]}
                    steps={[
                        {
                            title: 'price info',
                            content: data => <>
                                <div className="popup--form-item">
                                    <div className="popup--form-title">
                                        <span>
                                            price
                                        </span>
                                    </div>
                                    <div className="--Price">
                                        <J.Image width="25px" height="25px" src={currencyData.logo} />&nbsp;
                                        <span className="number">
                                            {(this.state.storage.punk.data.market_data.list.minValue / Math.pow(10, currencyData.decimals)).toLocaleString() || '-'}
                                        </span>
                                        <span className="sign">
                                            {currencyData.symbol}
                                        </span>
                                    </div>
                                </div>
                                <div className="popup--form-item">
                                    <div className="popup--form-title">
                                        <span>
                                            USD price
                                        </span>
                                    </div>
                                    <div className="--Blockchain" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <strong>$</strong>
                                        <span>
                                            {this.state.storage.punk.data.market_data.list.priceInUsd.toFixed(4) || 'NO DATA'}
                                        </span>
                                    </div>
                                </div>
                            </>,
                            action: () => {
                                return new Promise(async (resolve, reject) => {
                                    const price = data.minValue
                                    const proc_price = price / Math.pow(10, currencyData.decimals)
                                    if (currencyData.symbol != 'TRX') {
                                        const curContract = await this.CT.contract.apply(data.currencyAddress)
                                        const allowance = (await curContract.allowance(this.state.storage.wallet.address, this.CT.statics.contract.address.TPUNKS_MARKET).call()).toString()
                                        const proc_allowance = allowance / Math.pow(10, currencyData.decimals)
                                        const balance = (await curContract.balanceOf(this.state.storage.wallet.address).call())._hex
                                        const proc_balance = parseInt(balance, 16) / Math.pow(10, currencyData.decimals)
                                        if (proc_balance >= proc_price) resolve({ id: this.state.interf.id, price: proc_price, currencyAddress: data.currencyAddress, currencySymbol: currencyData.symbol, allowance: proc_allowance, fee: currencyData.marketFee })
                                        else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                    } else {
                                        if (this.state.storage.wallet.balance >= proc_price) resolve({ id: this.state.interf.id, price: proc_price, currencyAddress: data.currencyAddress, currencySymbol: currencyData.symbol, allowance: 1000000000000000000000000, fee: currencyData.marketFee })
                                        else reject(() => this.CT.toast('error', 'your balance is not enough'))
                                    }
                                })
                            }
                        },
                        {
                            title: 'Unlock currency',
                            content: data => <>
                                approve {data.price + ' ' + data.currencySymbol} on the market:
                                <br />
                                <br />
                                {this.state.interf.isApproving ? <C.Loading /> : (data.allowance >= data.price ? data.goNext() : <><J.Btn title="APPROVE" onClick={async () => this.__approveCurrency(data.currencyAddress, (data.price * Math.pow(10, currencyData.decimals))).then(res => res && data.change({ allowance: data.price }))} /></>)}
                            </>,
                            action: data => {
                                return new Promise((resolve, reject) => {
                                    data.allowance >= data.price ? resolve(data) : reject(() => this.CT.toast('error', 'first approve your currency on market'))
                                })
                            },
                        },
                        {
                            title: 'Accept action',
                            content: data => <>
                                <div className="popup--signbox">
                                    <span className="popup--signbox-head">TRANSACTION</span>
                                    <p>event: <strong>buy punk</strong></p>
                                    <p>punk: <strong> #{data.id}</strong></p>
                                    <p>price: <strong>{data.price}</strong>&nbsp;{data.currencySymbol}</p>
                                    {/* <p>market fee: <strong>{data.fee}</strong>%</p> */}
                                    <p>do you accept? </p>
                                </div>
                            </>
                        },
                    ]}
                    submitter={{
                        title: 'BUY PUNK',
                        iconName: 'ri-check-line',
                        action: data => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    const contract = isOldMarket ? await this.CT.contract.apply('TPUNKS_MARKET_OLD') : await this.CT.contract.apply('TPUNKS_MARKET')
                                    const TXN = isOldMarket
                                        ?
                                        await contract.buyPunk(data.id).send({
                                            feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress),
                                            callValue: data.currencySymbol == 'TRX' ? (data.price * Math.pow(10, getCurrencyData(data.currencyAddress).decimals)) : 0,
                                        })
                                        :
                                        await contract.buyToken(data.id).send({
                                            feeLimit: this.CT.statics.contract.feeLimitOn(data.currencyAddress),
                                            callValue: data.currencySymbol == 'TRX' ? (data.price * Math.pow(10, getCurrencyData(data.currencyAddress).decimals)) : 0,
                                        });
                                    if (TXN) {
                                        this.CT.toast('success', <div>Tpunk bought successfully.<br />Wait for 15 seconds, then refresh the page.</div>)
                                        this.CT.popup.confirm()
                                        resolve(true)
                                    } else {
                                        this.CT.toast('error', 'action not completed')
                                        this.CT.popup.cancel()
                                        reject()
                                    }
                                }
                                catch (err) {
                                    console.log(err)
                                    this.CT.toast('error', 'action rejected')
                                    this.CT.popup.cancel()
                                }
                            })
                        }
                    }}
                />
            </>
        })
            .then(async res => console.log('DONE'))
            .catch(err => this.CT.toast('info', err == 'DENY' && 'action canceled'));
    }
    async _connectToWallet() {
        let action = null
        try {
            action = window.tronWeb && await window.tronWeb.request({
                method: 'tron_requestAccounts'
            })
            action ? this.CT.toast('success', 'Wallet connected, refresh page.') : this.CT.toast('error', 'Wallet not connected, check your tronlink')
        }
        catch (err) {
            this.CT.toast('error', err)
        }
    }
    // 
    r_table_history() {
        const { storage, interf } = this.state
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        return (
            <table>
                <thead>
                    <tr>
                        <th>event</th>
                        <th>from</th>
                        <th>to</th>
                        <th>amount</th>
                        <th>date</th>
                    </tr>
                </thead>
                <tbody>
                    {storage.punk &&
                        (storage.punk.data && storage.punk.data.tradeHistory.map((trade, i) => {
                            return <tr key={i}>
                                <td>{this.CT.utils.eventTitle(trade.event_name)}</td>
                                <td>
                                    {trade.data.from ? this.CT.utils.walletAddress(trade.data.from) : ''}
                                </td>
                                <td>
                                    {trade.data.to ? this.CT.utils.walletAddress(trade.data.to) : ''}
                                </td>
                                <td>
                                    {storage.currencies && trade.data.currency && trade.data.value
                                        ?
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'table_price-' + i}>
                                            <J.Image width="20px" height="20px" src={getCurrencyData(trade.data.currency).logo} />
                                            {this.CT.tooltip(null, 'table_price-' + i, <>{(trade.data.value / Math.pow(10, getCurrencyData(trade.data.currency).decimals)).toLocaleString()}&nbsp;{getCurrencyData(trade.data.currency).symbol}</>)}
                                            <span>&nbsp;{this.CT.utils.normalizeNum(trade.data.value / Math.pow(10, getCurrencyData(trade.data.currency).decimals))}&nbsp;<strong style={{ opacity: '0.5' }}>{getCurrencyData(trade.data.currency).symbol}</strong></span>
                                        </div>
                                        :
                                        ''
                                    }
                                </td>
                                <td>
                                    {/* {storage.wallet && (trade.event_name == 'BidCreated'
                                        &&
                                        (trade.data.from == storage.wallet.address ? (interf.isWithdrawing ? 'wait' : <><J.Btn iconName="ri-delete-bin-6-line" title="WITHDRAW" onClick={() => this.bridge_withdrawBid()} /><br /></>) : '')
                                    )} */}
                                    {trade.event_name.toUpperCase() != 'MINTED'
                                        ?
                                        <a href={`${this.CT.statics.contract.address.TRONSCAN}/#/transaction/${trade.transaction_id}`} target="_blank">
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {new Date(Number(trade.block_timestamp)).toLocaleDateString("en-US")}
                                                &nbsp;
                                                <i className="ri-external-link-line"></i>
                                            </div>
                                        </a>
                                        :
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {new Date(Number(trade.block_timestamp)).toLocaleDateString("en-US")}
                                                &nbsp;
                                            </div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        }))
                    }
                </tbody>
            </table>
        )
    }
    r_table_bids() {
        const { storage, interf } = this.state
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        return (
            storage.punk.data.market_data.bids.length > 0 ?
                <div className="DataTable">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>price</th>
                                <th>USD price</th>
                                <th>bidder</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storage.punk.data.market_data.bids.map((bid, i) => {
                                return bid.hasBid && <tr key={i}>
                                    <td>
                                        {i + 1}
                                    </td>
                                    <td>
                                        {bid.value &&
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'bidtable_price-' + i}>
                                                <J.Image width="20px" height="20px" src={getCurrencyData(bid.currencyAddress).logo} />
                                                {this.CT.tooltip(null, 'bidtable_price-' + i, <>{(bid.value / Math.pow(10, getCurrencyData(bid.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(bid.currencyAddress).symbol}</>)}
                                                <span>&nbsp;{this.CT.utils.normalizeNum(bid.value / Math.pow(10, getCurrencyData(bid.currencyAddress).decimals))}&nbsp;<strong style={{ opacity: '0.5' }}>{getCurrencyData(bid.currencyAddress).symbol}</strong></span>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {bid.priceInUsd &&
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} data-place="bottom" data-tip data-for={'bidtable_usd-' + i}>
                                                <strong>$</strong>
                                                {this.CT.tooltip(null, 'bidtable_usd-' + i, <>{(bid.priceInUsd.toFixed(6)).toLocaleString()}</>)}
                                                <span>&nbsp;{this.CT.utils.normalizeNum(bid.priceInUsd.toFixed(2))}</span>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {this.CT.utils.walletAddress(bid.bidder, storage.wallet && (bid.bidder == storage.wallet.address && 'you'))}
                                    </td>
                                    <td>
                                        {storage.wallet &&
                                            (bid.bidder == storage.wallet.address ? (bid.on_old_market ? (interf.isWithdrawingOnOld ? 'wait' : <><J.Btn iconName="ri-delete-bin-6-line" title="CANCEL" onClick={() => this.bridge_withdrawBid(bid.on_old_market)} /></>) : (interf.isWithdrawingOnNew ? 'wait' : <><J.Btn iconName="ri-delete-bin-6-line" title="CANCEL" onClick={() => this.bridge_withdrawBid(bid.on_old_market)} /></>)) : (interf.iAm == 'OWNER' ? <J.Btn iconName="ri-check-fill" title="ACCEPT" onClick={() => this.bridge_sellToBid(bid)} /> : ''))
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <p>
                    there is no bid
                </p>
        )
    }
    r_priceChart() {
        const { storage, interf } = this.state
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        let data = storage.punk.data.priceHistory.length > 0 ? [] : null
        storage.punk.data.priceHistory.length > 0 && storage.punk.data.priceHistory.map(item => {
            data.push({
                price: item.avg,
                volume: item.volume,
                sales: item.count,
                date: new Date(Number(item.date)).toLocaleDateString("en-US")
            })
        })
        return (
            <>
                {data ?
                    <C.Content full horizontal>
                        <ResponsiveContainer width="100%" height="100%" minWidth={400}>
                            <AreaChart
                                width={500}
                                height={250}
                                data={data}
                                margin={{
                                    right: 20
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip itemStyle={{ display: 'none' }} labelStyle={{ fontSize: '14px' }} formatter={price => null} labelFormatter={(date, obj) => {
                                    return <>
                                        <div style={{ marginBottom: '8px' }}>date: <strong style={{ opacity: '0.7' }}>{obj[0] && obj[0].payload.date}</strong></div>
                                        <div style={{ marginBottom: '8px' }}>avg price: <strong style={{ opacity: '0.7' }}>{obj[0] && '$ ' + obj[0].payload.price.toFixed(2)}</strong></div>
                                        <div style={{ marginBottom: '8px' }}>volume: <strong style={{ opacity: '0.7' }}>{obj[0] && '$ ' + obj[0].payload.volume.toFixed(2)}</strong></div>
                                        <div style={{ marginBottom: '8px' }}>sales: <strong style={{ opacity: '0.7' }}>{obj[0] && obj[0].payload.sales}</strong></div>
                                    </>
                                }} />
                                <Area type="monotone" dataKey="price" stroke="#8884d8" fill="#8884d8" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </C.Content>
                    :
                    <C.Content full horizontal>
                        <ResponsiveContainer width="100%" height="100%" minWidth={400}>
                            <AreaChart
                                width={500}
                                height={250}
                                data={[]}
                                margin={{
                                    right: 20
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="price" stroke="#8884d8" fill="#8884d8" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </C.Content>
                }
            </>
        )
    }
    r_actions() {
        const { interf, form, storage } = this.state
        if (storage.punk.data)
            switch (interf.iAm) {
                case 'NOTHING':
                    return <>
                        nothing
                    </>
                case 'GUEST':
                    return <>
                        <J.Btn type="simple" title="CONNECT WALLET" iconName="ri-wallet-2-fill" onClick={() => this._connectToWallet()} />
                        {/* <J.Btn type="simple" title="CONNECT WALLET" iconName="ri-wallet-2-fill" onClick={() => this.bridge_makeBid()} /> */}
                    </>
                case 'USER':
                    return <>
                        {true && <J.Btn title="BID" iconName="ri-file-add-fill" onClick={() => this.bridge_makeBid()} />}
                        {storage.punk.data.market_data.list.isForSale && <J.Btn title="BUY NOW" iconName="ri-shopping-bag-3-fill" onClick={() => this.bridge_buybyOffer()} />}
                    </>
                case 'OWNER':
                    return <>
                        {storage.punk.data.market_data.list.isForSale ? (interf.isOfferCanceling ? <div className="--Loading"><J.Btn title={<C.Loading inner />} /></div> : <J.Btn title="DELIST TPUNK" iconName="ri-close-circle-fill" onClick={() => this.bridge_cancelOffer()} />) : <J.Btn title="SELL" iconName="ri-menu-add-line" onClick={() => this.bridge_makeOffer()} />}
                        {/* {storage.punk.data.market_data.bids.length > 0 && <J.Btn title="SELL TO LAST BID" iconName="ri-checkbox-circle-fill" onClick={() => this.bridge_sellToBid()} />} */}
                    </>
                default:
            }
    }
    // 
    setActiveTabOnTables(tab) {
        this._sst('interf', { activeTab_tables: tab })
    }
    render() {
        const [CT] = this.context
        const { interf, form, storage } = this.state
        const props = this.props
        const getCurrencyData = (address) => this.CT.utils.findInBy(address, this.state.storage.currencies, 'contractAddress')
        if (interf.id < 0 || interf.id > 10000 || isNaN(interf.id)) {
            return (
                <>
                    <Redirect to="/forSale" />
                </>
            )
        } else {
            return (
                <>
                    <div className="punk_info">
                        <C.Content>
                            <div className="head">
                                <div className="title">
                                    <Link className="local_action" to={props.location.query ? props.location.query.sender : `/forSale`}>
                                        <i className="ri-arrow-left-s-line"></i>
                                    </Link>
                                    <span>
                                        {interf.isLoading ? 'LOADING' : 'PUNK INFO'}
                                    </span>
                                </div>
                                <div className="actions">
                                    {!interf.isLoading && (storage.punk && (this.state.storage.currencies ? this.r_actions() : '...'))}
                                </div>
                            </div>
                            <div className="info">
                                <div className="image">
                                    <J.Image className="--Punk_image" src={this.CT.utils.pis(interf.id)} />
                                </div>
                                <div className="properties">
                                    {!interf.isLoading ?
                                        storage.punk &&
                                        (storage.punk.data
                                            ?
                                            <>
                                                <div className="part">
                                                    <div className="record">
                                                        <div className="value -name">
                                                            <span>
                                                                {storage.punk.idx}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                type
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <span>
                                                                {storage.punk.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                list currency
                                                            </span>
                                                        </div>
                                                        <div className="value -blockchain">
                                                            {storage.currencies && this.state.storage.currencies
                                                                ?
                                                                <>
                                                                    <J.Image src={getCurrencyData(storage.punk.data.market_data.list.currencyAddress).logo} />
                                                                    <span>
                                                                        {getCurrencyData(storage.punk.data.market_data.list.currencyAddress).name}
                                                                        &nbsp;-&nbsp;
                                                                        {getCurrencyData(storage.punk.data.market_data.list.currencyAddress).symbol}
                                                                    </span>
                                                                </>
                                                                :
                                                                '-'
                                                            }
                                                        </div>
                                                    </div> */}
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                attributes
                                                            </span>
                                                        </div>
                                                        <div className="value -attributes">
                                                            {storage.punk.attributes.map((attr, i) => {
                                                                return <span key={i}>
                                                                    {attr}
                                                                </span>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                owner
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            {interf.iAm == 'OWNER' ? CT.utils.walletAddress(storage.punk.owner, 'you') : (storage.punk.owner ? CT.utils.walletAddress(storage.punk.owner) : 'it can be you')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="part">
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                rank
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            {this.CT.storage.PUNKS[interf.id].rank}
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                listing price
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            {this.state.storage.currencies && storage.punk.data.market_data.list.isForSale
                                                                ?
                                                                <div className="--Price" data-tip data-for='list_price'>
                                                                    {storage.punk.data.market_data.list.minValue && this.CT.tooltip(null, 'list_price', <>{(storage.punk.data.market_data.list.minValue / Math.pow(10, getCurrencyData(storage.punk.data.market_data.list.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(storage.punk.data.market_data.list.currencyAddress).symbol}</>)}
                                                                    {storage.punk.data.market_data.list.minValue && <><J.Image width="30px" height="30px" src={getCurrencyData(storage.punk.data.market_data.list.currencyAddress).logo} />&nbsp;</>}
                                                                    <span className="number">
                                                                        {storage.punk.data && (storage.punk.data.market_data.list.isForSale
                                                                            ?
                                                                            (this.CT.utils.normalizeNum(storage.punk.data.market_data.list.minValue / Math.pow(10, getCurrencyData(storage.punk.data.market_data.list.currencyAddress).decimals)))
                                                                            :
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                    <span className="sign">
                                                                        {storage.punk.data &&

                                                                            getCurrencyData(storage.punk.data.market_data.list.currencyAddress).symbol

                                                                        }
                                                                    </span>
                                                                </div>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                highest bid
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            {this.state.storage.currencies &&
                                                                <div className="--Price -sub" data-tip data-for='highesbid_price'>
                                                                    {storage.punk.data._bid_highest && <><J.Image width="20px" height="20px" src={getCurrencyData(storage.punk.data._bid_highest.currencyAddress).logo} />&nbsp;</>}
                                                                    <span className="number">
                                                                        {storage.punk.data._bid_highest && this.CT.tooltip(null, 'highesbid_price', <>{(storage.punk.data._bid_highest.value / Math.pow(10, getCurrencyData(storage.punk.data._bid_highest.currencyAddress).decimals)).toLocaleString()}&nbsp;{getCurrencyData(storage.punk.data._bid_highest.currencyAddress).symbol}</>)}
                                                                        {storage.punk.data._bid_highest ? this.CT.utils.normalizeNum(storage.punk.data._bid_highest.value / Math.pow(10, getCurrencyData(storage.punk.data._bid_highest.currencyAddress).decimals)) : '-'}
                                                                    </span>
                                                                    <span className="sign">
                                                                        {storage.punk.data._bid_highest ? getCurrencyData(storage.punk.data._bid_highest.currencyAddress).symbol : ''}
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="part -full">
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                price history
                                                            </span>
                                                        </div>
                                                        <div className="value -chart">
                                                            {this.r_priceChart()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="part">
                                                    <div className="record">
                                                        <div className="value -name">
                                                            <span>
                                                                {storage.punk.idx}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                type
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            <span>
                                                                {storage.punk.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                attributes
                                                            </span>
                                                        </div>
                                                        <div className="value -attributes">
                                                            {storage.punk.attributes.map((attr, i) => {
                                                                return <span key={i}>
                                                                    {attr}
                                                                </span>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="record">
                                                        <div className="key">
                                                            <span>
                                                                owner
                                                            </span>
                                                        </div>
                                                        <div className="value">
                                                            wait until it is in the market
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        <div className="part -shadow">
                                            <div className="record" style={{ width: '320px', animationDelay: '0s' }}></div>
                                            <div className="record" style={{ width: '200px', animationDelay: '0.2s' }}></div>
                                            <div className="record" style={{ width: '250px', animationDelay: '0.35s' }}></div>
                                            <div className="record" style={{ width: '170px', animationDelay: '0.45s' }}></div>
                                            <div className="record" style={{ width: '190px', animationDelay: '0.52s' }}></div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {storage.punk && storage.punk.data &&
                                <div className="data">
                                    <div className="tabs">
                                        <div className={`tab ${interf.activeTab_tables == 'HISTORY' ? '-active' : ''}`} onClick={() => this.setActiveTabOnTables('HISTORY')}>
                                            <span>trading history</span>
                                        </div>
                                        <div className={`tab ${interf.activeTab_tables == 'BIDS' ? '-active' : ''}`} onClick={() => this.setActiveTabOnTables('BIDS')}>
                                            <span>bids {('(' + storage.punk.data.market_data.bids.length + ')') || 0}</span>
                                        </div>
                                    </div>
                                    <C.Content full>
                                        {interf.activeTab_tables == 'HISTORY'
                                            ?
                                            this.r_table_history()
                                            :
                                            (interf.activeTab_tables == 'BIDS')
                                                ?
                                                this.r_table_bids()
                                                :
                                                ''
                                        }
                                    </C.Content>
                                </div>}
                        </C.Content>
                    </div>
                </>
            )
        }
    }
}